import React from 'react';

import "./App.css";

import NutritionTable from "./nutrition_table/NutritionTable.js"
import RecipeEditor from "./food_input/RecipeEditor.js"
import IngredientAmount from "./model/IngredientAmount.js"
import ApiRecipeUtil from "./api/ApiRecipeUtil.js";
import ApiUsdaUtil from "./api/ApiUsdaUtil.js";
import UrlUtil from "./UrlUtil.js";

import {Alert, AlertTitle, Card, 
        Grid,
        Typography,
        Button,
    } from '@mui/material/';

class CreateRecipePage extends React.Component {
  constructor(props) {
    super(props);
    this.firstMountHasHappened = false;

    const ingredientAmounts = IngredientAmount.readIngredientAmountsFromUrl()

    const duplicates = IngredientAmount.getDuplicateIngredientAmounts(ingredientAmounts);
    const error = duplicates.length !== 0;
    const errorMessage = error ? "There are duplicated ingredients: " + JSON.stringify(duplicates, null, 2) : null;
    this.state = {
      ingredientAmounts: ingredientAmounts,
      ingredientsResponseData: Array(0),
      error: error,
      errorMessage: errorMessage,
      successMessage: null,
      recipeName: UrlUtil.getNamedUrlParam('recipeName'),
    };
  }

  componentDidMount() {
    if (this.state.ingredientAmounts.length > 1 && this.firstMountHasHappened === false) {
      this.searchForIngredientsIfChanged(this.state.ingredientAmounts);
      this.firstMountHasHappened = true;
    }
  }

  recipeChanged = (recipeAmounts, ingredientAmounts, recipeName) => {
    this.setUrl(ingredientAmounts, true);
    
    const duplicates = IngredientAmount.getDuplicateIngredientAmounts(ingredientAmounts);
    const error = duplicates.length !== 0;
    const errorMessage = error ? "There are duplicated ingredients: " + JSON.stringify(duplicates, null, 2) : null;

    this.setState({
      ingredientAmounts: ingredientAmounts,
      recipeName: recipeName,
      error: error,
      errorMessage: errorMessage
    });
    this.searchForIngredientsIfChanged(ingredientAmounts);
  }

  ingredientsDataCoversAllIngredientAmounts (ingredientAmounts)
  {
    let ingredientIds = this.state.ingredientsResponseData.map(fdcData => fdcData.fdc_id);

    for (let item of ingredientAmounts) {
      const id = item.fdc_id
      if (id !== null && id !== undefined) {
        // need this ID.
        if (!ingredientIds.includes(id))
        {
          return false;
        }
      }
    }
    return true;
  }

  searchForIngredientsIfChanged = (ingredientAmounts) => {
    if (this.ingredientsDataCoversAllIngredientAmounts(ingredientAmounts))
    {
      // console.log("Already have all required ingredients, skipping call.");
      return;
    }
    let fdc_ids = []
    for (let item of ingredientAmounts) {
      const id = item.fdc_id
      if (id) {
        fdc_ids.push(id);
      }
    }
    let self = this;
    ApiUsdaUtil.searchForIngredients({
      fdc_ids: fdc_ids,
      nutrient_ids: this.props.user.nutrients,
      success: (data) => {
        console.log("Setting ingredients data from server response");
        self.setState({
          ingredientsResponseData: data,
        });
      },
      failure: (message) => {
        console.log("Failed to get data from server. " + message);
        self.setState({
          errorMessage: "Problem querying the server. " + message,
        });
      }
    });
  }

  setUrl = (ingredients: Array, addToHistory: false) => {
    var params = IngredientAmount.getQueryParamsForIngredientAmounts(ingredients);
    if (this.state.recipeName)
    {
      if (params !== "")
      {
        params += "&";
      }
      params += "recipeName=" + encodeURIComponent(this.state.recipeName);
    }
    const urlPath = "?" + params;
    if (addToHistory) {
      window.history.pushState("Ingredients Updated","Some Title", urlPath);
    } else {
      window.history.replaceState("Ingredients Updated","Some Title", urlPath);
    }
  }

  saveAsRecipe() {
    let errors = [];
    if (!this.state.recipeName || this.state.recipeName === "")
    {
      errors.push("Recipe must have a name to be saved.");
    }

    this.state.ingredientAmounts.forEach(item => {
      if (item.fdc_id)
      {
        // If no fdc_id, it's an empty row, which is fine.
        if (!item.amount || !item.unit)
        {
          errors.push("Missing amount(" + item.amount + ")(and/or unit(" + item.unit + ")) - for " + item.fdcID + " - " + item.name);
        }
      }
    });
    for (let error of IngredientAmount.getDuplicateIngredientAmounts(this.state.ingredientAmounts))
    {
      errors.push("There are duplicated Ingredients: " + error);
    }
    console.log("Errors: " + JSON.stringify(errors, null, 2));

    if (errors.length === 0)
    {
      ApiRecipeUtil.createRecipe({
        recipeName: this.state.recipeName, 
        ingredientAmounts: this.state.ingredientAmounts,
        success: () => {
          console.log("Successfully saved recipe.")
          this.setState({
            errorMessage: "",
            successMessage: "Successfully saved recipe.",
          });
        },
        failure: (message) => {
          console.log("Failed to save recipe.")
          this.setState({
            errorMessage: "Server failed to save recipe. " + message,
            successMessage: "",
          });
        },
      })
    }
    else{
      this.setState({
        errorMessage: JSON.stringify(errors, null, 2),
        successMessage: "",
      });
    }
  }

  render() {
    var errorSection = this.state.errorMessage ? 
        (<Alert severity="error"><AlertTitle>Errors</AlertTitle><pre>{this.state.errorMessage}</pre></Alert>) 
        : "";
    var successSection = this.state.successMessage ? 
        (<Alert severity="success">{this.state.successMessage}</Alert>) 
        : "";
    var saveSection;
    if (!this.props.user)
    {
      saveSection = (
        <Card>
          <Alert severity="info" sx={{ maxWidth:400 }}>
            Login to save your recipes.
          </Alert>
        </Card>
      );
    } 
    else
    {
      saveSection = (
        <Button variant="contained"
          onClick={() => this.saveAsRecipe()}
        >
          Save Recipe
        </Button>
      );
    }
    return (
      <div className="App">
        <Typography variant="h3">Create New Recipe</Typography>
        <Typography variant="body1">Select ingredients &amp; amounts to find the total nutrition for your recipe/meal.</Typography><br/>
        <RecipeEditor 
          user={this.props.user}
          ingredientAmounts={this.state.ingredientAmounts}
          recipeName={this.state.recipeName}
          recipeChanged={this.recipeChanged}
          showName={true}
        />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={3}>
            {successSection}
          </Grid>      
          <Grid item xs={3}>
            {errorSection}
          </Grid>      
          <Grid item xs={3}>
            {saveSection}
          </Grid>      
        </Grid>
        <Typography variant="h2">Nutrition</Typography>
         <NutritionTable 
          user={this.props.user}
          ingredients={this.state.ingredientsResponseData}
          showSubTitles={false}
          showSubtotals={false}
          titleToShowRows={{"recipeIngredients": true}}
          titleToIngredientAmounts={{"recipeIngredients": this.state.ingredientAmounts}}/>
      </div>
    );
  }
}
  
export default CreateRecipePage;