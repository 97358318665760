import React from 'react';

import LoginForm from "./LoginForm.js"

import "./App.css";

import {Container, Typography, Link} from '@mui/material/';


class LoginPage extends React.Component {
  render() {
    return (
      <Container 
        maxWidth="lg"
        className="App"
      >
        <Typography variant="h3">Login</Typography>
        <LoginForm user={this.props.user} updateUser={this.props.updateUser} redirectPath={this.props.redirectPath}/>
        {this.props.user ? "" : (<div>Or: <Link href="/createAccount">Create New Account</Link></div>)}
      </Container>
    );
  }
}
  
export default LoginPage;