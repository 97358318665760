import CONFIG from "./../config.js"

import $ from "jquery";

const ApiUsdaUtil = {

  searchForIngredients: ({
    fdc_ids,
    nutrient_ids,
    success,
    failure
  }) => {
    let idSet = new Set()
    fdc_ids.forEach(item => idSet.add(item))

    let fdc_ids_string = ""
    idSet.forEach( id => {
      if (id !== null && id !== undefined) {
        if (fdc_ids_string !== "") {
          fdc_ids_string += ","
        }
        fdc_ids_string += id;
      }
    });
    console.log("Searching for fdc_ids: " + fdc_ids_string);

    const url = CONFIG.API_PATH + "/usda/foods?fdc_ids=" + fdc_ids_string + "&nutrient_numbers=" + nutrient_ids.join(',');
    $.ajax({
        url: url,
        type: "GET",
        dataType:'json',
        success(responseData, textStatus, jqXhr) {
          const data = responseData && Object.keys(responseData).length > 0 ? responseData : { status: "Server Response Was Empty"};
          if (data['status'] === "OK") {
            success(responseData['usdaFoodData']);
          } else {
            failure("Server responded, but there was still a problem querying the server. " + data['status']);
          }
        },
        error(jqXhr, textStatus: String, errorThrown: String) {
          failure("Problem querying the server. " + textStatus + ". " + errorThrown + ". (" + url + ")");
        },
    })
  }
}

export default ApiUsdaUtil;