import CONFIG from "./../config.js"

import ApiUtil from "./ApiUtil.js";

import $ from "jquery";

const ApiRecipeUtil = {

  createRecipe: ({
    recipeName, 
    recipeDescription = "",
    finalAmount = null, 
    finalAmountUnit = null,
    ingredientAmounts,
    success,
    failure
  }) => {
    let ingredients = [];
    ingredientAmounts.forEach( item => {
      if (item.fdc_id)
      {
        let ingredient = {
          name: item.name,
          fdc_id: item.fdc_id,
          amount: item.amount,
          amount_unit: item.unit
        };
        ingredients.push(ingredient);
      }
    });
    let data = {
      name: recipeName,
      descriptin: recipeDescription,
      final_amount: finalAmount,
      final_amount_unit: finalAmountUnit,
      ingredients: ingredients,
    };


    const url = CONFIG.API_PATH + "/recipe";

    ApiUtil.ajaxPostSetup();
    $.post({
      url: url, 
      data: data, 
      dataType: 'json'})
      .done((responseData, textStatus, jqXhr)=>  {
          success();
      })
      .fail((jqXhr, textStatus: String, errorThrown: String) => {
          failure("Failed to save the recipe. " + jqXhr.responseText);
    });
  },

  deleteRecipe: ({
      id,
      success,
      failure
  }) => {
    const url = CONFIG.API_PATH + "/recipe/" + id;

    $.ajax({
      url: url,
      method: 'DELETE',
      contentType: 'text/plain',
      
      xhrFields: {
          withCredentials: true
      }
    })
    .done((responseData, textStatus, jqXhr)=>  {
        success();
    })
    .fail((jqXhr, textStatus: String, errorThrown: String) => {
        failure("Failed to delete the recipe. " + jqXhr.responseText);
    }); 
  }
}

export default ApiRecipeUtil;
