

// Unencoded characters, use these to make parameters easier to read:
//  _-()*&!~.
export default class UrlUtil {

  static getNamedUrlParams(key) {
    let allParams = UrlUtil.getEncodedUrlParams();
    let result = allParams[encodeURIComponent(key)]
    if (result)
    {
      result = result.map(val => decodeURIComponent(val));
    }
    return result;
  }

  static getNamedUrlParam(key) {
    let values = UrlUtil.getNamedUrlParams(key);
    if (values && values.length > 0)
    {
      return values[0];
    }
    else
    {
      return null;
    }
  }

  static getEncodedUrlParams()
  {
    // TODO: THis is very brittle! Use a library!
    var queryString = window.location.search;
    if (queryString.startsWith('?'))
    {
      queryString = queryString.substr(1)
    }
    const urlParams = queryString.split("&");
    var result = {};
    for (let param of urlParams)
    {
      const parts = param.split('=');
      if (!result[parts[0]]) 
      {
        result[parts[0]] = Array(0);
      } 
      result[parts[0]] = result[parts[0]].concat(parts[1]);
    }
    return result;
  }

  static encodeKeyValueParam(key, value)
  {
    return encodeURIComponent(key) + "=" + encodeURIComponent(value);
  }
}