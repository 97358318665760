
import foodToFdcId from "./../data/foodToFdcId.json"
import UrlUtil from "./../UrlUtil.js"

// Should be renamed 'IngredientAmount'
class IngredientAmount 
{
  constructor(
    name: String,
    fdc_id: Long,
    amount: Float,
    unit: String,
  ){
    this.name = name ? name : null;
    this.fdc_id = fdc_id ? parseInt(fdc_id) : null;
    this.amount = amount ? amount : null;
    this.unit = unit ? unit : "g";
  }

  hasId() {
    return this.fdc_id !== undefined && this.fdc_id !== null;
  }

  getId() {
    return this.fdc_id;
  }

  static reverse(dict) {
    const reversed = {}
    for (let key of Object.getOwnPropertyNames(dict)) {
      reversed[dict[key]] = key;
    }
    return reversed;
  }

  static getQueryParamsForIngredientAmounts(items) {
    let params = ""
    for (let ingredientAmount of items) {
      if (!ingredientAmount.fdc_id) {
        continue;
      }
      if (params !== "") {
        params += "&";
      }
      params += UrlUtil.encodeKeyValueParam("i", IngredientAmount.serializeIngredientAmountForUrl(ingredientAmount));
    }
    return params;
  }

  static serializeIngredientAmountForUrl(ingredient) {
    const amount = ingredient.amount ? ingredient.amount : "_";
    const unit = ingredient.unit === null || ingredient.unit === undefined ? "_" : ingredient.unit;
    const fdc_id = ingredient.fdc_id === null || ingredient.fdc_id === undefined ? "_" : ingredient.fdc_id;
    return "USDA_" + fdc_id + "!" + amount + "!" + unit;
  }

  static deserializeIngredientAmountForUrl(text) {
    const parts = text.split("!");
    const fdc_id = parts[0].split("_")[1] === "_" ? null : parts[0].split("_")[1];
    const amount: Float = parts[1] === "_" ? null : parseFloat(parts[1]);
    const unit = parts[2] === "_" ? "g" : parts[2];
    const name = FDC_ID_TO_NAME[fdc_id];
    console.log("Parsed recipe Item: " + fdc_id + " - " + amount + unit + " - " + name);
    return new IngredientAmount(name, fdc_id, amount, unit);
  }

  static readIngredientAmountsFromUrl() {
    var result = Array(0);

    const serialized = UrlUtil.getNamedUrlParams('i');
    if (serialized) {
      for (let i of serialized)
      {
        const item = IngredientAmount.deserializeIngredientAmountForUrl(i);
        if (item.fdc_id)
        {
          result.push(item);
        }
      }
    }
    result.push(new IngredientAmount());
    return result;
  }

  static getDuplicateIngredientAmounts(ingredientAmounts: Array): Boolean {
    let duplicateItems = [];
    const seenIds = [];
    for (let ingredientAmount of ingredientAmounts)
    {
      const id = ingredientAmount.fdc_id;
      if (seenIds.includes(id))
      {
        let text = ingredientAmount.name + " (" + ingredientAmount.fdc_id + ")";
        if (!duplicateItems.includes(text))
        {
          duplicateItems.push(text);
        }
      }
      seenIds.push(id);
    }
    return duplicateItems;
  }
}

const FDC_ID_TO_NAME = IngredientAmount.reverse(foodToFdcId.foodToFdcId);

export default IngredientAmount;