import React from 'react';

import ApiUsdaUtil from "./api/ApiUsdaUtil.js"
import ApiRecipeUtil from "./api/ApiRecipeUtil.js";

import NutritionTable from "./nutrition_table/NutritionTable.js"
import IngredientAmount from "./model/IngredientAmount.js"

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// import $ from "jquery";
import "./App.css";

import {Container, Alert, AlertTitle,
        Typography,
        Grid, Card,
        CardActions, IconButton, Button,
    } from '@mui/material/';


class ViewMyRecipesPage extends React.Component 
{  
  constructor(props) {
    super(props);
    this.state = {
      usdaData: Array(0),
      errorMessage: null,
      titleToShowRows: {}
    }
  }

  componentDidMount() {
    this.componentDidUpdate();
  }
  componentDidUpdate() {
    if (this.props.user && this.state.usdaData.length === 0)
    {
      this.updateRecipes(this.props.user.recipes);
    }
  }

  setDefaultShowRows = (recipes) => {
    let updatedTitleToShowRows = {...this.state.titleToShowRows};
    let change = false;
    for (let recipe of recipes)
    {
      if (!(recipe['name'] in updatedTitleToShowRows))
      {
        updatedTitleToShowRows[recipe['name']] = false; // Default to hide rows
        change = true;
      }
    }
    if (change)
    {
      this.setState({
        titleToShowRows: updatedTitleToShowRows,
      });
    }
  }

  updateRecipes = (recipes) => {
    this.setDefaultShowRows(recipes);
    let fdc_ids = [];
    recipes.forEach( 
      recipe => {
        if (recipe.ingredients)
        {
          recipe.ingredients.forEach(
            i => fdc_ids.push(i.fdc_id)
          )
        }
      }
    );
    if (fdc_ids.length > 0)
    {
      this.updateUsdaInfoForFdcIds(fdc_ids);
    }
    else
    {
      console.log("0 fdc_ids found.");
    }
  }

  updateUsdaInfoForFdcIds(fdc_ids)
  {
    ApiUsdaUtil.searchForIngredients({
      fdc_ids: fdc_ids,
      nutrient_ids: this.props.user.nutrients,
      success: (data) => {
        console.log("Successful result for IDs. " + JSON.stringify(fdc_ids));
        this.setState({
          usdaData: data,
        });
      },
      failure: (message) => {
        console.log("Failure: " + message);
      }
    });
  }

  showHideNutritionRows = (mealName, showRows) => {
    let updatedShowRows  = {...this.state.titleToShowRows};
    let show = showRows === null || showRows === undefined ? !this.state.titleToShowRows[mealName] : showRows;
    updatedShowRows[mealName] = show;
    console.log("ShowHideRows: " + mealName + " - " + showRows + " :Result: " + JSON.stringify(updatedShowRows));
    this.setState({
      titleToShowRows: updatedShowRows,
    });
  }

  deleteRecipe(recipe) {
    let id = recipe.id;
    ApiRecipeUtil.deleteRecipe({
      id: id,
      success: () => {
        this.setState({
          successMessage: "Deleted recipe " + id + ". '" + recipe.name + "'.",
          errorMessage: null,
        });
        console.log("Deleted recipe " + id);
        this.props.requestUserUpdate();
      },
      failure: (message) => {
        this.setState({
          successMessage: null,
          errorMessage: "Failed to deleting recipe " + id + ". " + message,
        });
        console.log("Failed to deleting recipe " + id + ". " + message);
      } 
    });
  }


  renderRecipe(recipe) {
    let name = recipe['name'];
    let showRows = this.state.titleToShowRows[name];
    return (
      <Card  
        key={recipe['id']}
        sx={{ m: 4 }}>
        <Typography gutterBottom variant="h4" component="div">
          <IconButton 
            color="primary" 
            aria-label="show/hide rows" 
            onClick={() => {
              this.showHideNutritionRows(name, !showRows);
            }} >
              {showRows ? <VisibilityOff /> : <Visibility />}
          </IconButton>
          {name}
        </Typography>
        <NutritionTable 
          user={this.props.user}
          ingredients={this.state.usdaData}
          showSubTitles={false}
          showSubtotals={false}
          titleToShowRows={this.state.titleToShowRows}
          titleToIngredientAmounts={{[name]: recipe.ingredients.map(
            item => {
              return new IngredientAmount(item.name, item.fdc_id, item.amount, item.amount_unit);
            }
          )}}
        />

          <CardActions>
            <Button size="small" href={"/editRecipe?" + IngredientAmount.getQueryParamsForIngredientAmounts(recipe.ingredients) + "&recipeName=" + encodeURIComponent(recipe.name)}>Edit Recipe</Button>
            <Button size="small" onClick={() => {this.deleteRecipe(recipe)}}>Delete Recipe</Button>
          </CardActions>
      </Card>
    );
  }
  /* Props
   * * onLoginSuccess: function(String)
   */
  render() {
    if (!this.props.user) {
      return (
        <Alert severity="error">
          <AlertTitle>Not logged in!</AlertTitle>
          You must be logged in to view your account details.
        </Alert>
      );
    }

    let successSection = this.state.successMessage ? (<Alert severity="success">{this.state.successMessage}</Alert>) : "";
    let errorSection = this.state.errorMessage ? (<Alert severity="error">{this.state.errorMessage}</Alert>) : "";

    let recipes = "";
    if (this.props.user && this.props.user.recipes) 
    {
       recipes = ( this.props.user.recipes.map((recipe) => this.renderRecipe(recipe)));
    }
    
    return (
      <Container 
        
        maxWidth="lg"
        className="App">
        {successSection}
        {errorSection}
        <Grid>
          <Grid>
            <Typography variant="h2">Recipes</Typography>
            {recipes}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

  
export default ViewMyRecipesPage;