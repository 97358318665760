import React from 'react';

import LoginPage from "./LoginPage.js"

import "./App.css";


import {Container, Alert, AlertTitle} from '@mui/material/';


class AccessDeniedPage extends React.Component {
  render() {
    return (
      <Container 
        maxWidth="lg"
        className="App"
      >
        <Alert severity="error"><AlertTitle>Access Denied</AlertTitle><pre>You must be logged in to view this page.</pre></Alert>
        <LoginPage user={this.props.user} updateUser={this.props.updateUser} redirectPath={this.props.redirectPath}/>
      </Container>
    );
  }
}
  
export default AccessDeniedPage;