import React from 'react';

import AmountTextField from './AmountTextField.js';
import {Stack,
        Autocomplete, TextField,
        Button, IconButton, Tooltip,
        } from '@mui/material/';
import DeleteIcon from '@mui/icons-material/Delete';

import useMediaQuery from '@mui/material/useMediaQuery';

export default function RecipeSelector(props) {
  /* Props:
   *   * user: 
   *   * index: int
   *   * recipe: RecipeAmount? // the selected recipe
   *   * onChanged: func(index: int, name: string)
   *   * onAmountChanged: func(index: int, amount: float, unit: string)
   *   * onRemoved: func(index: int)
   */
  const isMobile = useMediaQuery('(max-width:1024px)');

  const renderDeleteButton = () => {
    if (isMobile)
    {
      return (
        <IconButton 
          color="primary" 
          aria-label="delete ingredient" 
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      );
    } else
    {
      return (
        <Button 
          variant="contained" 
          aria-label="delete ingredient"
          startIcon={<DeleteIcon />}
          onClick={() => props.onRemoved(props.index)}
          sx={{ height: '100% '}}
          size='large'
        >
          {isMobile ? "" : "Remove"}
        </Button>
      );
    }
  }

  const getValidRecipeNames = () => {
    if (!props.user || !props.user.recipes )
    {
      return []
    }
    return props.user.recipes.map(r => r.name);
  }

  var validIngredientNames = getValidRecipeNames();
  var id = null;
  var name = null;
  var amount = null;
  var unit = null;
  var tooltip = "";
  if (props.recipe) {
    id = props.recipe.id;
    name = props.recipe.name
    amount = props.recipe.amount;
    unit = props.recipe.unit === null ? unit : props.recipe.unit;
    tooltip = props.error ? "Recipe alredy selected earlier." : tooltip;
  }
  return (
    <Stack
      direction="row" 
      spacing={1}
      sx={{ m: 1 }}
      alignItems="center"
    >
      <Tooltip title={tooltip}>
        <Autocomplete
          disablePortal
          id="ingredient-entry-field"
          options={validIngredientNames}
          value={name || null}
          sx={{ width: 1/2, minWidth: 200 }}
          renderInput={(params) => <TextField {...params}
                                      size={isMobile ? "small": "normal"} 
                                      error={props.error}
                                      label={"Recipe " + (props.index+1)}
                                    />
          }
          onChange={(event, value, reason, details) => props.onChanged(props.index, value)}
        />
      </Tooltip>
      <TextField
        className="redundantInfo"
        label="id"
        value={id || "-"}
        size={isMobile ? "small": "normal"}
        variant="filled"
        sx={{ width: '5em'}}
        disabled
      />
      <AmountTextField index={props.index} id={id} amount={amount} unit={unit} onAmountChanged={props.onAmountChanged}/>
      
      {renderDeleteButton()}
    </Stack>
  );
}
