import $ from "jquery";

const ApiUtil = 
{
  ajaxPostSetup: () => {
    $.ajaxSetup({
        crossDomain: true,
        xhrFields: {
            withCredentials: true
        }
    });
  },

  padTo2Digits: (num) => {
    return num.toString().padStart(2, '0');
  },

  formatDate: (date = new Date()) => {
    return [
      date.getFullYear(),
      ApiUtil.padTo2Digits(date.getMonth() + 1),
      ApiUtil.padTo2Digits(date.getDate()),
    ].join('');
  },

  parseDate: (str) => {
    if (!str || str.length !== 8)
    {
      return null;
    }
    const y = str.substr(0, 4)
    const m = str.substr(4, 2) - 1
    const d = str.substr(6, 2);
    const date = new Date(y, m, d);
    return (date.getFullYear() === +y && date.getMonth() === +m && date.getDate() === +d) ? date : null;
  }
}

export default ApiUtil;
