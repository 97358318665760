import React from 'react';
import foodToFdcId from "./../data/foodToFdcId.json"

import AmountTextField from "./AmountTextField.js";

import {Stack,
        Autocomplete, TextField,
        Button, IconButton, Tooltip,
        } from '@mui/material/';
import DeleteIcon from '@mui/icons-material/Delete';

import useMediaQuery from '@mui/material/useMediaQuery';


export default function IngredientSelector(props) { // extends React.PureComponent {
  /* Props:
   * onChanged: func(index: int, name: string)
   * onAmountChanged: func(index: int, amount: float, unit: string)
   * onRemoved: func(index: int)
   * index: int
   * ingredient: RecipeItem? // the selected ingredient
   */
  const isMobile = useMediaQuery('(max-width:1024px)');

  const renderDeleteButton = () => {
    if (isMobile)
    {
      return (
        <IconButton 
          color="primary" 
          aria-label="delete ingredient" 
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      );
    } else
    {
      return (
        <Button 
          variant="contained" 
          aria-label="delete ingredient"
          startIcon={<DeleteIcon />}
          onClick={() => props.onRemoved(props.index)}
          sx={{ height: '100% '}}
          size='large'
        >
          {isMobile ? "" : "Remove"}
        </Button>
      );
    }
  }

  var validIngredientNames = Object.keys(foodToFdcId.foodToFdcId);
  var id = null;
  var name = null;
  var amount = null;
  var unit = null;
  var tooltip = "";
  if (props.ingredient !== undefined && props.ingredient !== null) {
    id = props.ingredient.getId();
    name = props.ingredient.name
    amount = props.ingredient.amount;
    unit = props.ingredient.unit === null ? unit : props.ingredient.unit;
    tooltip = props.error ? "Ingredient alredy selected earlier." : tooltip;
  }
  return (
    <Stack
      direction="row" 
      spacing={1}
      sx={{ m: 1 }}
      alignItems="center"
    >
      <Tooltip title={tooltip}>
        <Autocomplete
          disablePortal
          id="ingredient-entry-field"
          options={validIngredientNames}
          value={name || null}
          sx={{ width: 1/2, minWidth: 200 }}
          renderInput={(params) => <TextField {...params} 
                                      size={isMobile ? "small": "normal"}
                                      error={props.error}
                                      label={"Ingredient " + (props.index+1)}
                                    />
          }
          onChange={(event, value, reason, details) => props.onChanged(props.index, value)}
        />
      </Tooltip>
      <TextField
        className="redundantInfo"
        id="id-disabled"
        label="id"
        value={id || "-"}
        size={isMobile ? "small": "normal"}
        variant="filled"
        sx={{ width: '5em'}}
        disabled
      />
      <AmountTextField 
        index={props.index} 
        id={id} 
        amount={amount} 
        unit={unit} 
        onAmountChanged={props.onAmountChanged}/>
      
      {renderDeleteButton()}
    </Stack>
  );
  
}
