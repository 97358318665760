import React from 'react';

import "./../App.css";

import IngredientSelector from "./IngredientSelector.js"
import RecipeSelector from "./RecipeSelector.js";
import IngredientAmount from "./../model/IngredientAmount.js"
import RecipeAmount from "./../model/RecipeAmount.js";

import App from "./../App.js";

import foodToFdcId from "./../data/foodToFdcId.json"

import {Link, TextField, Container, Divider, Typography, Tooltip} from '@mui/material/';

import useMediaQuery from '@mui/material/useMediaQuery';

const RecipeNameTextField = ({recipeName, nameChanged}) => (
  <TextField 
    label="Recipe Name" 
    defaultValue={recipeName}
    variant="standard" 
    onBlur={nameChanged}
    sx={{ width: 1/2, m: 1 }}
  />
);

export default function RecipeEditor(props)
{
  /** Props:
   *   * user
   *   * recipeAmounts
   *   * ingredientAmounts
   *   * recipeName
   *   * allowRecipeAmounts
   *   * showName
   *   * showSaveAsRecipe
   *   * recipeChanged
   */
  const isMobile = useMediaQuery('(max-width:1024px)');

  const getRecipeId = (name) => {

    if (props.user && props.user.recipes)
    {
      const theRecipe = props.user.recipes.find(recipe => {
        return recipe.name === name;
      });
      return theRecipe ? theRecipe.id : undefined;
    }
    return undefined;
  }

  const removeRecipe = (index): Void => {
    let updatedRecipeAmounts = commonRemove(
      "recipe", 
      index, 
      props.recipeAmounts, 
      () => new RecipeAmount(), 
    );

    props.recipeChanged(updatedRecipeAmounts, props.ingredientAmounts, props.recipeName);
  }

  const setRecipe = (index, name): Void => {
    let id = getRecipeId(name);
    let updatedRecipeAmounts = commonSet (
      "recipe", 
      index, 
      name, 
      props.recipeAmounts, 
      (item) => {item.id = id},
      () => new RecipeAmount() );


    console.log("Updated: " + JSON.stringify(updatedRecipeAmounts));

    props.recipeChanged(updatedRecipeAmounts, props.ingredientAmounts, props.recipeName);
  }

  const setRecipeAmount = (index, amount, unitName): Void => {
    let updatedRecipeAmounts = commonSetAmount(
      "recipe", 
      index, 
      amount, 
      unitName, 
      props.recipeAmounts,
      () => new RecipeAmount());

    props.recipeChanged(updatedRecipeAmounts, props.ingredientAmounts, props.recipeName);
  }

  const removeIngredient = (index): Void => {
    let updatedIngredientAmounts = commonRemove(
      "ingredient", 
      index, 
      props.ingredientAmounts, 
      () => new IngredientAmount(), 
    );
    props.recipeChanged(props.recipeAmounts, updatedIngredientAmounts, props.recipeName);
  }

  const setIngredient = (index, name): Void => {
    let id = foodToFdcId.foodToFdcId[name];
    let updatedIngredientAmounts = commonSet (
      "ingredient", 
      index, 
      name, 
      props.ingredientAmounts, 
      (item) => {item.fdc_id = id},
      () => new IngredientAmount() );

    props.recipeChanged(props.recipeAmounts, updatedIngredientAmounts, props.recipeName);
  }

  const setIngredientAmount = (index: Int, amount: Float, unitName: String): Void => {
    let updatedIngredientAmounts = commonSetAmount(
      "ingredient", 
      index, 
      amount, 
      unitName, 
      props.ingredientAmounts,
      () => new IngredientAmount());

    props.recipeChanged(props.recipeAmounts, updatedIngredientAmounts, props.recipeName);
  }

  const commonSetAmount = (type: String, index: Int, amount: Float, unitName: String, amounts, emptyReplacementFactory) => {
    console.log("Updating " + type + " at " + index + " amount to: " + amount + "" + unitName);
    var updatedAmounts = [...amounts];
    let item = emptyReplacementFactory();
    Object.assign(item, updatedAmounts[index]);
    item.amount = amount;
    item.unit = unitName;
    updatedAmounts[index] = item;
    console.log("Updated: " + JSON.stringify(updatedAmounts));
    

    return updatedAmounts
  }

  const commonSet = (type, index, name, amounts, setIdFunction, emptyReplacementFactory) => {
    var updatedAmounts = [...amounts];
    let item = emptyReplacementFactory();
    Object.assign(item, updatedAmounts[index]);
    item.name = name;
    setIdFunction(item);
    updatedAmounts[index] = item;
    
    if (index === amounts.length - 1 && name)
    {
      // Add another object for adding onto.
      updatedAmounts.push(emptyReplacementFactory());
    }
    return updatedAmounts;
  }

  const commonRemove = (type, index, amounts, emptyReplacementFactory) => {
    console.log("Removing " + type + " at " + index); 
    var updatedAmounts = [...amounts];
    updatedAmounts.splice(index, 1);
    if (updatedAmounts.length === 0 || updatedAmounts[updatedAmounts.length-1].hasId())
    {
      updatedAmounts.push(emptyReplacementFactory());
    }
    return updatedAmounts;
  }

  const nameChanged = (event) => {
    if (event.target.value !== props.recipeName)
    {
      props.recipeChanged(props.recipeAmounts, props.ingredientAmounts, event.target.value);
    }
  }

  const buildIngredientSelectors = () => {
    var selectedIngredients = Array(0);
    var seenIds = new Set();
    for (let i = 0; i < props.ingredientAmounts.length; i++)
    {
      let ingredientAmount = props.ingredientAmounts[i];
      const error = seenIds.has(ingredientAmount.fdc_id);
      seenIds.add(ingredientAmount.fdc_id);
      let key = ingredientAmount.fdc_id === null ? Math.random().toFixed(10) : ingredientAmount.fdc_id;
      selectedIngredients.push(<IngredientSelector 
            key={key}
            index={i}
            ingredient={ingredientAmount}
            onChanged={setIngredient}
            onAmountChanged={setIngredientAmount}
            onRemoved={removeIngredient}
            error={error}
          />);
    }
    return selectedIngredients;
  }

  const buildRecipeSelectors = () => {
    if (!props.allowRecipeAmounts)
    {
      return [];
    }

    let selectedRecipes = Array(0);
    let seenRecipeIds = new Set();
    for (let i = 0; i < props.recipeAmounts.length; i++)
    {
      let recipeAmount = props.recipeAmounts[i];
      const error = seenRecipeIds.has(recipeAmount.id);
      seenRecipeIds.add(recipeAmount.id)
      let key = recipeAmount.id === null ? Math.random().toFixed(10) : recipeAmount.id;
      selectedRecipes.push(<RecipeSelector 
            key={key}
            index={i}
            user={props.user}
            recipe={recipeAmount}
            onChanged={setRecipe}
            onAmountChanged={setRecipeAmount}
            onRemoved={removeRecipe}
            error={error}
          />);
    }
    return selectedRecipes;
  }

  const getCreateRecipeLink = () => {
    return App.CREATE_RECIPE_PATH + "?" + IngredientAmount.getQueryParamsForIngredientAmounts(props.ingredientAmounts);
  }

  let selectedIngredients = buildIngredientSelectors();
  let selectedRecipes = buildRecipeSelectors();
  let both = selectedIngredients.length > 0 && selectedRecipes.length > 0;
  let recipeNameSection = props.showName ? <RecipeNameTextField recipeName={props.recipeName} nameChanged={nameChanged}/> : "";
  return (
    <Container>
      {recipeNameSection}
      {
        selectedIngredients.length === 0 ? "" : 
        <div>
          {
            isMobile ? "" : 
              <Typography variant="h5">Ingredients</Typography>
          }
          {selectedIngredients}
          { !props.showSaveAsRecipe ? "" :
            <Tooltip title="Save these ingredients together as a 'recipe' so you can easily log them together in future.">
            <div>
                <Link href={getCreateRecipeLink()}>Save As Recipe</Link>
              </div>
            </Tooltip>
          }
        </div>
      }
      {both ? <Divider variant="middle" /> : ""}
      {
        selectedRecipes.length === 0 ? "" : 
        <div>
          {
            isMobile ? "" : 
              <Typography variant="h5">Recipes</Typography>
          }
          {selectedRecipes}
        </div>
      }
    </Container>
  );
}
  