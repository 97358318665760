import nutrientToIdAndCount from "./nutrientToIdAndCount.json";

function reverseIdToNutrient() {
  let reversed = {}
  for (let key of Object.getOwnPropertyNames(nutrientToIdAndCount.nutrientToIdAndCount)) {
    reversed[nutrientToIdAndCount.nutrientToIdAndCount[key]['id']] = key;
  }
  return reversed;
}
const ID_TO_NUTRIENT = reverseIdToNutrient();

const NutrientDataUtil = {
  getCountForNutrient: (name) => {
    return nutrientToIdAndCount.nutrientToIdAndCount[name]['count'];
  },

  getNutrientForId: (id) => {
    return ID_TO_NUTRIENT[id];
  },

  getIdForNutrient: (name) => {
    return nutrientToIdAndCount.nutrientToIdAndCount[name]['id'];
  },

  allNutrientIds: () => {
    return Object.keys(ID_TO_NUTRIENT);
  },

  allNutrientNames: () =>{
    return Object.keys(nutrientToIdAndCount.nutrientToIdAndCount)
  },

}

export default NutrientDataUtil;