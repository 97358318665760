import Cookies from 'js-cookie';

const CookieUtil = {

  getUsernameFromCookie: () => {
    let authCookie = Cookies.get('auth');
    if (!authCookie) {
      return null;
    }

    let username = JSON.parse(authCookie)['username']
    if (!username || username === "")
    {
      return null;
    }
    return username;
  },

  hasAuthCookie: () => {
    let authCookie = Cookies.get('auth');
    return authCookie ? true : false;
  },

};

export default CookieUtil;