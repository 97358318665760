import React from 'react';

import Nutrient from "./Nutrient.js";
import NutritionSubTable from "./NutritionSubTable.js";

import NutrientDataUtil from "./../data/NutrientDataUtil.js";
import StringUtil from "./../utils/StringUtil.js";

import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TableFooter } from '@mui/material/';


class NutritionTable extends React.PureComponent {
  /** Props:
   *  user: user
   *  showSubtotalsAtTop: boolean
   *  showSubtotalsAtBottom: boolean
   *  showSubTitles: boolean
   *  ingredients: List<Ingredient: (JSON Object from USDA.gov)>
   * 
   *  titleToShowRows: Map<Title, Boolean>
   *  titleToIngredientAmounts: Map<Title, List<IngredientAmount>
   *  titleToRecipeAmounts: Map<Title, List<RecipeAmount>>
   *  showHideRowsFunction: Function<Title, Boolean?>
   */

  calculateTotalAmount() {
    var total = 0.0;
    for (let ingredientAmounts of Object.values(this.props.titleToIngredientAmounts)) {
      for (let ingredientAmount of ingredientAmounts) {
        if (ingredientAmount && ingredientAmount.fdc_id)
        {
          var amount = ingredientAmount.amount ? ingredientAmount.amount :  0;
          if (!isNaN(amount)) {
            total += amount;
          }
        }
      }
    }
    if (this.props.titleToRecipeAmounts)
    {
      Object.values(this.props.titleToRecipeAmounts).forEach(recipeAmounts => {
        recipeAmounts.forEach(ra => {
          total += ra.getTotalAmount();
        });
      });
    }

    return total;
  }
  calculateTotalCalories() {
    if (!this.hasNonEmptyRecipe())
    {
      return "-";
    }
    var total = 0.0;
    for (let ingredientAmounts of Object.values(this.props.titleToIngredientAmounts)) {
      for (let ingredientAmount of ingredientAmounts) {
        var calories = Nutrient.findCaloriesInAmount(this.props.ingredients, ingredientAmount, 0.0);
        if (!isNaN(calories)) {
          total = total + calories;
        }
      }
    }
    if (this.props.titleToRecipeAmounts)
    {
      Object.values(this.props.titleToRecipeAmounts).forEach(recipeAmounts => {
        recipeAmounts.forEach(ra => {
          total += ra.getTotalCalories(this.props.ingredients);
        });
      });
    }
    return total;
  }
  formatFloat(value, places=1)
  {
    if (isNaN(value)) {
      return value;
    }
    return value.toFixed(places)
  }

  getTotalNutrientUnit(nutrientId)
  {
    let unit = undefined;
    Object.values(this.props.titleToIngredientAmounts)
      .forEach(ingredientAmounts => {
        ingredientAmounts
          .map(item => Nutrient.getIngredientForIngredientAmount(this.props.ingredients, item))
          .forEach(ingredient => {
            let nutrientPer100g = Nutrient.findNutrientByNumber(ingredient, nutrientId);
            if (nutrientPer100g)
            {
              if (nutrientPer100g.unit) {
                unit = nutrientPer100g.unit;
              } else {
                if (unit && unit !== ingredient.unit)
                {
                  return "!";
                }
              }
            }
          });
      })
      return unit ? unit : '?';
  }

  getTotalNutrientText(nutrientId)
  {
    if (!this.hasNonEmptyRecipe()) {
      return "-";
    }
    let unit = this.getTotalNutrientUnit(nutrientId);
    let total = 0.0;
    Object.values(this.props.titleToIngredientAmounts)
      .forEach( ingredientAmounts => {
        total += Nutrient.calculateTotalNutrientAmountById(this.props.ingredients, ingredientAmounts, nutrientId)
      });
    if (this.props.titleToRecipeAmounts)
    {
      Object.values(this.props.titleToRecipeAmounts)
        .forEach( recipeAmounts => {
          recipeAmounts.forEach( ra => {
            total += ra.getTotalNutrient(this.props.ingredients, nutrientId)
          });
        });
    }
    return this.formatFloat(total) + (unit ? unit : "?");
  }

  hasNonEmptyRecipe()
  {
    if (!this.props.titleToIngredientAmounts)
    {
      return false;
    }
    for (let ingredientAmounts of Object.values(this.props.titleToIngredientAmounts)) {
      for (let item of ingredientAmounts) {
        if (item && item.fdc_id)
        {
          return true;
        }
      }
    }
    return false;
  }

  renderTableFooter()
  {
    let nutrientTotals = [];
    if (this.props.user)
    {
      this.props.user.nutrients.forEach(nutrient_number => {
          nutrientTotals.push(<TableCell key={nutrient_number} align="right" sx={{ fontSize: 'h6.fontSize' }} >{this.getTotalNutrientText(nutrient_number)}</TableCell>);
      })
    }
    return (
      <TableFooter>
        <TableRow
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell sx={{ fontSize: 'h6.fontSize' }} >Totals</TableCell>
          <TableCell align="right" sx={{ fontSize: 'h6.fontSize' }} >{this.formatFloat(this.calculateTotalAmount())} g</TableCell>
          <TableCell align="right" sx={{ fontSize: 'h6.fontSize' }} >{this.formatFloat(this.calculateTotalCalories())}</TableCell>
          {nutrientTotals}
        </TableRow>
      </TableFooter>
    );
  }

  getAllTitles() {
    let allTitles = this.props.titleToIngredientAmounts == null ? [] : Object.keys(this.props.titleToIngredientAmounts);
    let otherTitles = this.props.titleToRecipeAmounts ? Object.keys(this.props.titleToRecipeAmounts) : [];
    for (let title of otherTitles)
    {
      if (allTitles.indexOf(title) === -1) {
        allTitles.push(title);
      }
    }
    return allTitles;
  }
  
  render() {
    let subTables = this.getAllTitles().map(title => {
              let ingredientAmounts = this.props.titleToIngredientAmounts == null ? [] : this.props.titleToIngredientAmounts[title];
              let recipeAmounts = this.props.titleToRecipeAmounts == null ? [] : this.props.titleToRecipeAmounts[title];
              return <NutritionSubTable 
                key={title}
                user={this.props.user}
                title={StringUtil.capitalizeFirstLetter(title)}
                showTitle={this.props.showSubTitles}
                showSubtotalsAtTop={this.props.showSubtotalsAtTop}
                showSubtotalsAtBottom={this.props.showSubtotalsAtBottom}
                showRows={this.props.titleToShowRows[title]}
                showHideRowsFunction={this.props.showHideRowsFunction}
                ingredients={this.props.ingredients}
                ingredientAmounts={ingredientAmounts}
                recipeAmounts={recipeAmounts}
                />
            });
    let nutrientHeadings = [];
    if (this.props.user)
    {
      this.props.user.nutrients.forEach(nutrient_number => {
          let nutrientName = NutrientDataUtil.getNutrientForId(nutrient_number);
          nutrientHeadings.push(<TableCell key={nutrient_number} align="right" sx={{ fontWeight: 'bold' }}>{nutrientName}</TableCell>);
      })
    }
    return (
      <TableContainer >
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="ingredients table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Ingredient</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>Amount</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>Calories</TableCell>
              {nutrientHeadings}
            </TableRow>
          </TableHead>
          <TableBody>
            {subTables}
          </TableBody>
          {this.renderTableFooter()}
        </Table>
      </TableContainer>
    );
  }
}

export default NutritionTable;
