import Nutrient from "./../nutrition_table/Nutrient.js";

class RecipeAmount 
{
  constructor(
    name: String,
    id: Long,
    amount: Float,
    unit: String,
    ingredient_amounts: Array
  ){
    this.name = name ? name : null;
    this.id = id ? parseInt(id) : null;
    this.amount = isNaN(amount) ? 100 : amount;
    this.unit = unit ? unit : "%";
    this.ingredient_amounts = ingredient_amounts ? ingredient_amounts : null;
  }

  hasId() {
    return this.id !== undefined && this.id !== null;
  }

  getId() {
    return this.id;
  }

  getAllIngredientFdcIds() 
  {
    if (this.ingredient_amounts === null)
    {
      return [];
    }
    return this.ingredient_amounts.map(amount => amount.fdc_id);
  }

  getTotalAmount()
  {
    if (!this.hasId())
    {
      return 0;
    }
    if (!this.ingredient_amounts)
    {
      return "MA"; // Missing amounts
    }
    if (this.unit !== "%") {
      return "UU"; // unsupported unit
    }
    let total = 0.0;
    let unit = null;
    this.ingredient_amounts.forEach(ia => {
      if (unit === null)
      {
        unit = ia.unit;
      }
      if (unit !== ia.unit) 
      {
        return "MU"; // multiple units
      }
      total += ia.amount;
    });
    return total * (this.amount/100);
  }
  getTotalAmountUnit()
  {
    if (!this.ingredient_amounts)
    {
      return "MA"; // Missing amounts
    }
    let unit = null;
    this.ingredient_amounts.forEach(ia => {
      if (unit === null)
      {
        unit = ia.unit;
      }
      if (unit !== ia.unit) 
      {
        return "MU"; // multiple units
      }
    });
    return unit;
  }

  getTotalCalories(ingredients) 
  {
    if (!this.hasId())
    {
      return 0;
    }
    if (!this.ingredient_amounts)
    {
      return "MA"; // Missing amounts
    }
    if (this.unit !== "%") {
      return "UU"; // unsupported unit
    }
    let total = Nutrient.calculateTotalCalories(ingredients, this.ingredient_amounts);
    return total * (this.amount/100);
  }
  getTotalNutrient(ingredients, nutrientId) 
  {
    if (!this.hasId())
    {
      return 0;
    }
    if (this.ingredient_amounts === null)
    {
      return "MA"; // Missing amounts
    }
    if (this.unit !== "%") {
      return "UU"; // unsupported unit
    }
    let total = Nutrient.calculateTotalNutrientAmountById(ingredients, this.ingredient_amounts, nutrientId);
    return total * (this.amount/100);
  }

  static getDuplicateIngredientAmounts(recipeAmounts: Array): Boolean 
  {
    let duplicateItems = [];
    const seenIds = [];
    for (let recipeAmount of recipeAmounts)
    {
      const id = recipeAmount.id;
      if (seenIds.includes(id))
      {
        let text = recipeAmount.name + " (" + recipeAmount.id + ")";
        if (!duplicateItems.includes(text))
        {
          duplicateItems.push(text);
        }
      }
      seenIds.push(id);
    }
    return duplicateItems;
  }
}
export default RecipeAmount;