import React from 'react';

// import $ from "jquery";
import "./App.css";

import NutrientDataUtil from "./data/NutrientDataUtil.js";

import {Container, Alert, AlertTitle,
        Typography, Link, 
        Grid, Card, CardContent, Checkbox,
        List, ListItem, ListItemIcon, ListItemButton, ListItemText,
    } from '@mui/material/';


class ViewAccountPage extends React.PureComponent {
  render() {
    if (!this.props.user) {
      return (
        <Alert severity="error">
          <AlertTitle>Not logged in!</AlertTitle>
          You must be logged in to view your account details.
        </Alert>
      );
    }
    let otherCards;
    if (!this.props.user)
    {
      otherCards = "";
    }
    else
    {
      let nutrients = this.props.user && this.props.user.nutrients ?
        this.props.user.nutrients
            .map( nutrientId => {
              let nutrientName = NutrientDataUtil.getNutrientForId(nutrientId);
              if (nutrientName)
              {
                return (
                  <ListItem 
                    key={nutrientName}
                  >
                    <ListItemText primary={nutrientName} />
                  </ListItem>
                )
              }
              return null;
            })
        : "?";
      otherCards = (
        <div>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Email
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {this.props.user.email}
            </Typography>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Member Since
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {this.props.user.created_at}
            </Typography>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Recipes
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {this.props.user && this.props.user.recipes ? this.props.user.recipes.length : "?"}
            </Typography>
            <Link href="/my/recipes">View Recipes</Link>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Nutrient Display Preferences
            </Typography>
            <List
              dense={true}>
              {nutrients}
            </List>
            <Link href="/my/nutrients">Edit Nutrient Display Preferences</Link>
          </CardContent>
        </Card>
        </div>
      );
    }

    
    return (
      <Container 
        maxWidth="lg"
        className="App">
        <Typography variant="h2">Account Info</Typography>
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid>
            <Card sx={{ minWidth: 300 }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Username
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {this.props.user ? this.props.user.name : "?"}
                </Typography>
              </CardContent>
            </Card>
            {otherCards}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

  
export default ViewAccountPage;