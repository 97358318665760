import * as React from "react";

import CreateRecipePage from "./CreateRecipePage.js";
import LoginPage from "./LoginPage.js";
import CreateAccountPage from "./CreateAccountPage.js";
import LoggedOutPage from "./LoggedOutPage.js";
import ViewAccountPage from "./ViewAccountPage.js";
import ViewMyRecipesPage from "./ViewMyRecipesPage.js";
import FoodLogPage from "./FoodLogPage.js";
import NutrientPreferenceEditPage from "./NutrientPreferenceEditPage.js";

import ApiUserUtil from "./api/ApiUserUtil.js";
import ApiUtil from "./api/ApiUtil.js";

import SiteHeader from "./SiteHeader.js"

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.firstMountHasHappened = false;
    this.state = {
      user: null,
    };
  }

  static FOOD_LOG_PATH = "/my/foodlog";
  static EDIT_RECIPE_PATH = "/editRecipe";
  static CREATE_RECIPE_PATH = App.EDIT_RECIPE_PATH;
  static MY_ACCOUNT_PATH = "/my/account";
  static MY_RECIPES_PATH = "/my/recipes";

  componentDidMount() {
    if (this.firstMountHasHappened === false) {
      this.getUserInfo();
      this.firstMountHasHappened = true;
    }
  }
  

  getUserInfo = () => {
    var self = this;
    ApiUserUtil.requestUserInfo({
      foundUserInfo: (userInfo) => {
        console.log("Verified Logged In as " + userInfo.name);
        self.setState({
          user: userInfo,
        });
      },
      userInfoNotFound: (message) => {
        console.log(message);
        self.setState({
          user: null,
        });
      }
    });
  }

  updateUser = (user) => {
    this.setState({
      user: user,
    });
  }

  render() {
    return (
      <div>
        <SiteHeader user={this.state.user} updateUser={this.updateUser} />
        <Router>
          <Routes>
            <Route exact path="/" 
                element={<Navigate to={ App.FOOD_LOG_PATH + "?date=" + ApiUtil.formatDate() } replace />} />
            <Route
              path={App.CREATE_RECIPE_PATH}
              element={<CreateRecipePage user={this.state.user} updateUser={this.updateUser} />}
            />
            <Route
              path="/login"
              element={<LoginPage user={this.state.user} updateUser={this.updateUser} />}
            />
            <Route
              path="/loggedOut"
              element={<LoggedOutPage user={this.state.user} updateUser={this.updateUser} />}
            />
            <Route
              path="/createAccount"
              element={<CreateAccountPage user={this.state.user} updateUser={this.updateUser} />}
            />
            <Route
              path="/my"
              element={<ViewAccountPage user={this.state.user} updateUser={this.updateUser} />}
            />
            <Route
              path={App.FOOD_LOG_PATH}
              element={<FoodLogPage user={this.state.user} updateUser={this.updateUser} />}
            />
            <Route
              path={App.MY_ACCOUNT_PATH}
              element={<ViewAccountPage user={this.state.user} updateUser={this.updateUser} />}
            />
            <Route 
              path="/my/nutrients"
              element={<NutrientPreferenceEditPage user={this.state.user} updateUser={this.updateUser} />}
            />
            <Route
              path={App.MY_RECIPES_PATH}
              element={<ViewMyRecipesPage user={this.state.user} updateUser={this.updateUser} requestUserUpdate={this.getUserInfo}/>}
            />
          </Routes>
        </Router>
      </div>
    );
  }
}

export default App;