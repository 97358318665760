import CONFIG from "./../config.js"

import CookieUtil from "./CookieUtil.js"
import ApiUtil from "./ApiUtil.js";

import $ from "jquery";

const ApiUserUtil = {

  requestUserInfo: ({
    foundUserInfo,
    userInfoNotFound
  }) => {
    let username = CookieUtil.getUsernameFromCookie();
    /* returns the info object returned from /user/info (or null.) */
    if (!username){
      userInfoNotFound("No username in cookie to verify.")
      return;
    }

    const url = CONFIG.API_PATH + "/user/info/" + username + "?includeRecipes=full&includeNutrients=true";
    $.ajax({
        url: url,
        type: "GET",
        dataType:'json',
        xhrFields: {
          // Set cookies
          withCredentials: true
        },
        success(responseData, textStatus, jqXhr) {
          foundUserInfo(responseData);
        },
        error(jqXhr, textStatus: String, errorThrown: String) {
          userInfoNotFound("Failed to get user info. " + jqXhr.responseText);
        },
    })
  },

  requestLogOut: ({
    logoutSuccess,
    logoutFailed
  }) => {
    let username = CookieUtil.getUsernameFromCookie();
    
    if (!username){
      if (CookieUtil.hasAuthCookie())
      {
        logoutFailed("No user found on auth cookie (but there is an auth cookie!)");
      }
      else
      {
        // Already logged out, I'd call that a success.
        logoutSuccess();
      }
      return;
    }

    const url = CONFIG.API_PATH + "/user/logout";
    $.ajax({
        url: url,
        type: "GET",
        dataType:'json',
        xhrFields: {
          // Set cookies
          withCredentials: true
        },
        success(responseData, textStatus, jqXhr) {
          logoutSuccess()
        },
        error(jqXhr, textStatus: String, errorThrown: String) {
          logoutFailed(jqXhr.responseText);
        },
    })
  },
  
  handleCreateAccountClick: ({
    username, 
    password,
    email,
    createSuccess,
    createFailed
  }) => {
    const url = CONFIG.API_PATH + "/user/create/" + username + '?password=' + encodeURIComponent(password) + '&email=' + encodeURIComponent(email);
    $.ajax({
        url: url,
        type: "GET",
        dataType:'json',
        success(responseData, textStatus, jqXhr) {
          createSuccess();
        },
        error(jqXhr, textStatus: String, errorThrown: String) {
          let message = JSON.parse(jqXhr.responseText)['message'];
          createFailed("Account Creation Failed. " + message);
        },
    })
  },

  storeNutrientPreferences: ({
      nutrients, // Array
      success, 
      failure
    }) => {
      if (nutrients === undefined || nutrients === null || !Array.isArray(nutrients) || nutrients.length < 1)
      {
        failure("nutrients passed is not a valid, non-empty, array");
        return;
      }
      let username = CookieUtil.getUsernameFromCookie();
      const url = CONFIG.API_PATH + "/user/info/" + username + "/nutrients";
      let data = {
        nutrient_numbers: nutrients
      };

      console.log("Going to post to : " + url + "\n " + JSON.stringify(data));

      ApiUtil.ajaxPostSetup();
      $.post({
        url: url, 
        data: data, 
        dataType: 'json'})
        .done((responseData, textStatus, jqXhr)=>  {
            success();
        })
        .fail((jqXhr, textStatus: String, errorThrown: String) => {
            failure("Failed to save the nutrient preferences. " + jqXhr.responseText);
      });
    }
}

export default ApiUserUtil;