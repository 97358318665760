import React from 'react';

import Nutrient from "./Nutrient.js";

import { TableRow, TableCell, IconButton,
        CircularProgress, Box
    } from '@mui/material/';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

class NutritionSubTable extends React.PureComponent {
  /** Props:
   *   * user: User
   *   * title: String?
   *   * showTitle: Boolean
   *   * showSubtotalsAtTop
   *   * showSubtotalsAtBottom
   *   * showRows
   *   * ingredients: List<Ingredient: (JSON Object from USDA.gov)>
   *   * ingredientAmounts: List<IngredientAmount>
   *   * recipeAmounts: List<RecipeAmount>
   *   * showHideRowsFunction: Function<Title, Boolean?>
   */

  formatAmount(ingredientAmount) {
    if (ingredientAmount.unit !== 'g') {
      throw new Error("Only supporting 'g'(grams) for now. Found: " + ingredientAmount.unit);
    }
    if (ingredientAmount && ingredientAmount.amount)
    {
      return ingredientAmount.amount.toFixed(1) + " " + ingredientAmount.unit;
    }
    return undefined;
  }
  findAmountNumber(ingredient) {
    for (let item of this.props.ingredientAmounts) {
      if (item.fdc_id === ingredient['fdc_id']) {
        var amount = item.amount;
        return amount ? amount : 0;
      }
    }
    return undefined;
  }

  formatFloat(value, places=1)
  {
    if (isNaN(value)) {
      return value;
    }
    return parseFloat(value).toFixed(places)
  }

  getTotalNutrientText(nutrientId)
  {
    if (!Nutrient.hasNonEmptyRecipe(this.props.ingredientAmounts)) {
      return "-";
    }
    let unit = Nutrient.getTotalNutrientUnit(this.props.ingredients, this.props.ingredientAmounts, nutrientId);
    let total = Nutrient.calculateTotalNutrientAmountById(this.props.ingredients, this.props.ingredientAmounts, nutrientId);
    this.props.recipeAmounts.forEach(ra => {
      total += ra.getTotalNutrient(this.props.ingredients, nutrientId)
    });
    return this.formatFloat(total) + (unit ? unit : "?");
  }

  getNutrientText(ingredientAmount, ingredient, nutrientId, defaultResult="-")
  {
    return this.formatFloat(Nutrient.findAmountOfNutrient(ingredientAmount, ingredient, nutrientId, defaultResult="-")) +  Nutrient.findNutrientUnit(ingredient, nutrientId, "?");
  }

  getRecipeNutrientText(recipeAmount, nutrientId)
  {
    let unit = Nutrient.getTotalNutrientUnit(this.props.ingredients, recipeAmount.ingredient_amounts, nutrientId);
    return this.formatFloat(recipeAmount.getTotalNutrient(this.props.ingredients, nutrientId)) + (unit ? unit : "?");
  }

  getTotalAmount() 
  {
    let total = Nutrient.calculateTotalAmount(this.props.ingredientAmounts);
    this.props.recipeAmounts.forEach(ra => {
      total += ra.getTotalAmount();
    });
    return total;
  }

  getTotalCalories()
  {
    let total = Nutrient.calculateTotalCalories(this.props.ingredients, this.props.ingredientAmounts);
    this.props.recipeAmounts.forEach(ra => {
      total += ra.getTotalCalories(this.props.ingredients);
    });
    return total;
  }

  renderRecipeAmountRow(recipeAmount)
  {
    if (recipeAmount.id === null)
    {
      return "";
    }
    let nutrientAmounts = [];
    if (this.props.user)
    {
      this.props.user.nutrients.forEach(nutrient_number => {
          nutrientAmounts.push(<TableCell key={nutrient_number} align="right">{this.getRecipeNutrientText(recipeAmount, nutrient_number)}</TableCell>);
      })
    }
    return (
      <TableRow
        key={this.props.title + recipeAmount.name}
      >
        <TableCell component="th" scope="row">
          {recipeAmount.name}
        </TableCell>
        <TableCell align="right">{recipeAmount.getTotalAmount()} {recipeAmount.getTotalAmountUnit()}</TableCell>
        <TableCell align="right">{this.formatFloat(recipeAmount.getTotalCalories(this.props.ingredients))}</TableCell>
        {nutrientAmounts}
      </TableRow>
    );
  }

  renderIngredientAmountRow(ingredientAmount)
  {
    let ingredient = Nutrient.getIngredientForIngredientAmount(this.props.ingredients, ingredientAmount);
    if (!ingredient)
    {
      return "";
    }
    let calories = Nutrient.findCaloriesInAmount(this.props.ingredients, ingredientAmount, "-");
    let nutrientAmounts = [];
    if (this.props.user)
    {
      this.props.user.nutrients.forEach(nutrient_number => {
          nutrientAmounts.push(<TableCell key={nutrient_number} align="right">{this.getNutrientText(ingredientAmount, ingredient, nutrient_number)}</TableCell>);
      })
    }
    return (
      <TableRow
        key={this.props.title + ingredient['description']}
      >
        <TableCell component="th" scope="row">
          {ingredient['description']}
        </TableCell>
        <TableCell align="right">{this.formatAmount(ingredientAmount)}</TableCell>
        <TableCell align="right">{this.formatFloat(calories)}</TableCell>
        {nutrientAmounts}
      </TableRow>
    );
  }

  canShowHide = () => {
    return this.props.showHideRowsFunction !== undefined && this.props.showHideRowsFunction !== null;
  }

  renderSubtitleDescription() 
  {
    let icon = this.canShowHide() ? 
        <IconButton 
          color="primary" 
          aria-label="show/hide rows" 
          onClick={() => {
            this.props.showHideRowsFunction(this.props.title, !this.props.showRows);
          }} >
            {this.props.showRows ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      : "";
      let text = this.props.title !== null ?
              this.props.title
              : "Subtotal";

      return (
        <div>
          {icon}
          {text}
        </div>
      );
  }

  renderTitleRow() {
    return (
      <TableRow
        key={this.props.title + ".Section-Title." + this.props.title}>
        <TableCell 
          colSpan={6} 
          sx={{ fontSize: 'h5.fontSize' }} >
          {
            this.canShowHide() ? 
              <IconButton 
                color="primary" 
                aria-label="show/hide rows" 
                onClick={() => {
                  this.props.showHideRowsFunction(this.props.title, !this.props.showRows);
                }} >
                  {this.props.showRows ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            : ""
          }
          {this.props.title}
        </TableCell>
      </TableRow>
    );
  }

  renderSubtotalRow() {
      let nutrientSubtotals = [];
      if (this.props.user)
      {
        this.props.user.nutrients.forEach(nutrient_number => {
            nutrientSubtotals.push(
              <TableCell key={"Subtotal_" + nutrient_number} align="right" sx={{ fontSize: 'h6.fontSize' }} >{this.getTotalNutrientText(nutrient_number)}</TableCell>
            );
        })
      }
      
      let description = this.renderSubtitleDescription();
      
      return (
        <TableRow
          key={this.props.title + "TableSubRow.Subtotals"}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell sx={{ fontSize: 'h6.fontSize' }} >{description}</TableCell>
          <TableCell align="right" sx={{ fontSize: 'h6.fontSize' }} >{this.formatFloat(this.getTotalAmount())} g</TableCell>
          <TableCell align="right" sx={{ fontSize: 'h6.fontSize' }} >{this.formatFloat(this.getTotalCalories())}</TableCell>
          {nutrientSubtotals}
        </TableRow>
      );
  }

  render() {
    if (Nutrient.hasNonEmptyRecipe(this.props.ingredientAmounts) && (!this.props.ingredients || this.props.ingredients.length === 0))
    {
      return (    
        <TableRow><TableCell colSpan={5}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        </TableCell></TableRow>
      );
    } else {

      let rowsResult = [];
      if (this.props.showSubtotalsAtTop === true) {
        rowsResult.push(this.renderSubtotalRow());
      }
      if (this.props.showRows)
      {
        let ingredientRows = this.props.ingredientAmounts.map((ingredientAmount) => this.renderIngredientAmountRow(ingredientAmount));
        let recipeRows = this.props.recipeAmounts.map((recipeAmount) => this.renderRecipeAmountRow(recipeAmount));

        ingredientRows.forEach(row => rowsResult.push(row));
        recipeRows.forEach(row => rowsResult.push(row));
      }

      if (this.props.showSubtotalsAtBottom === true) {
        rowsResult.push(this.renderSubtotalRow());
      }
      if (this.props.showTitle && this.props.title !== null && this.props.title !== "")
      {
        rowsResult.unshift(
          this.renderTitleRow()
        );
      }
      return rowsResult;
    }
  }
}

export default NutritionSubTable;
