
// Should be renamed 'IngredientAmount'
class Nutrient 
{
  constructor(
    name: String,
    amount: Long,
    unit: String
  ){
    this.name = name;
    this.amount = amount;
    this.unit = unit ? unit : "g";
  }

  static FAT_ID = '204';
  static CARBS_ID = '205';
  static PROTEIN_ID = '203';
  static ENERGY_ID = '208';
  static SODIUM_ID = '307';


  static findAmountOfNutrient(ingredientAmount, ingredient, nutrientId, defaultResult="-")
  {
    let nutrientPer100g = Nutrient.findNutrientByNumber(ingredient, nutrientId);
    if (!nutrientPer100g)
    {
      return defaultResult;
    }
    let result = (ingredientAmount.amount/100) * nutrientPer100g.amount;
    return isNaN(result) ? defaultResult : result;
  }

  static findNutrientUnit(ingredient, nutrientId, defaultResult="?") {
    let nutrientPer100g = Nutrient.findNutrientByNumber(ingredient, nutrientId);
    return nutrientPer100g && nutrientPer100g.unit ? nutrientPer100g.unit : defaultResult;
  }

  static findNutrientByNumber(ingredient, number) {
    if (!ingredient || !ingredient['foodNutrients'])
    {
      return null;
    }
    let nutrients = ingredient['foodNutrients'];
    for (let i = 0; i < nutrients.length; i++)
    {
      var nutrient = nutrients[i];
      if (nutrient['nutrient'] === undefined) {
        // abridged
        if (nutrient['number'] === number)
        {
          return new Nutrient(nutrient['description'], nutrient['amount'], nutrient['unitName']);
        }
      }
      else
      {
        // full
        if (nutrient['nutrient']['number'] === number) {
          return new Nutrient(nutrient['nutrient']['name'], nutrient['amount'], nutrient['nutrient']['unitName']);
        }
      }
    }
    return null;
  }

  static findNutrientAmountByNumber(ingredient, number, defaultValue=undefined) {
    var nutrient = Nutrient.findNutrientByNumber(ingredient, number, defaultValue);
    if (nutrient !== null) {
      return nutrient.amount;
    }
    return defaultValue;
  }

  static getIngredientForIngredientAmount(ingredients, ingredientAmount) {
    for (let i = 0; i < ingredients.length; i++)
    {
      let ingredient = ingredients[i];
      if (ingredientAmount.fdc_id === ingredient.fdcId) {
        return ingredient;
      }
    }
    return null;
  }

  static findAmountOfIngredient(ingredientAmounts, ingredient) {
    for (let item of ingredientAmounts) {
      if (item.fdc_id === ingredient.fdcId) {
        var amount = item.amount;
        return amount ? amount : 0;
      }
    }
    return undefined;
  }

  static calculateTotalNutrientAmountById(ingredients, ingredientAmounts, nutrientId)
  {
    if (!ingredients)
    {
      return 0.0;
    }
    var total = 0.0;
    for (let ingredientAmount of ingredientAmounts) {
      let ingredient = Nutrient.getIngredientForIngredientAmount(ingredients, ingredientAmount);
      if (ingredient) {
        var nutrientAmount = Nutrient.findNutrientAmountByNumber(ingredient, "" + nutrientId, undefined);
        var amountOfIngredient = Nutrient.findAmountOfIngredient(ingredientAmounts, ingredient);
        if (!isNaN(amountOfIngredient) && !isNaN(nutrientAmount)) {
          total += (nutrientAmount/100) * amountOfIngredient
        }
      }
    }
    return total;
  }


  static findNutrientLabelByNumber(ingredient, number) {
    var nutrient = Nutrient.findNutrientByNumber(ingredient, number);
    if (nutrient !== null) {
      return nutrient.amount.toFixed(1) + " " + nutrient.unit;
    }
    return "?";
  }


  static findCaloriesInAmount(ingredients, ingredientAmount, defaultResult) {
    let ingredient = Nutrient.getIngredientForIngredientAmount(ingredients, ingredientAmount)
    if (!ingredient)
    {
      return defaultResult;
    }
    var caloriesPer100g = Nutrient.findCalories(ingredient);
    if (ingredientAmount.amount === undefined || caloriesPer100g === undefined)
    {
      return defaultResult;
    }
    return (ingredientAmount.amount * caloriesPer100g) / 100
  }

  static findCalories(ingredient) {
    const declaredCalories = Nutrient.findNutrientAmountByNumber(ingredient, Nutrient.ENERGY_ID, null);
    if (declaredCalories)
    {
      return declaredCalories
    }
    return Nutrient.calculateCalories(ingredient);
  }

  static calculateCalories(ingredient) {
    var nutrientConversionFactors = ingredient['nutrientConversionFactors'];
    if (nutrientConversionFactors === undefined) {
      console.log('No conversionFactors found for ' + ingredient['description']);
      return undefined;
    }
    var calorieConversionFactors;
    for (let i = 0; i < nutrientConversionFactors.length; i++) {
      if (nutrientConversionFactors[i]['type'] === ".CalorieConversionFactor") {
        calorieConversionFactors = nutrientConversionFactors[i];
      }
    }
    if (calorieConversionFactors === undefined) {
      console.log('No CalorieConcersionFactors found for ' + ingredient['description']);
      return undefined;
    }
    var protein = Nutrient.findNutrientAmountByNumber(ingredient, Nutrient.PROTEIN_ID, 0.0);
    if (protein !== 0.0) {
      protein = protein *  calorieConversionFactors['proteinValue']
    }
    var carbs = Nutrient.findNutrientAmountByNumber(ingredient, Nutrient.CARBS_ID, 0.0);
    if (carbs !== 0.0) {
      carbs = carbs * calorieConversionFactors['carbohydrateValue'];
    }
    var fat = Nutrient.findNutrientAmountByNumber(ingredient, Nutrient.FAT_ID, 0.0);
    if (fat !== 0.0) {
      fat = fat * calorieConversionFactors['fatValue'];
    }
    return protein + carbs + fat;
  }
  
  static calculateTotalAmount(ingredientAmounts) {
    var total = 0.0;
    for (let item of ingredientAmounts) {
      if (item && item.fdc_id)
      {
        var amount = item.amount ? item.amount :  0;
        if (!isNaN(amount)) {
          total += amount;
        }
      }
    }
    return total;
  }
  
  static hasNonEmptyRecipe(ingredientAmounts)
  {
    if (!ingredientAmounts)
    {
      return false;
    }
    for (let item of ingredientAmounts) {
      if (item && item.fdc_id)
      {
        return true;
      }
    }
    return false;
  }

  static getTotalNutrientUnit(ingredients, ingredientAmounts, nutrientId)
  {
    let unit = undefined;
    if (ingredientAmounts)
    {
      ingredientAmounts
        .map(item => Nutrient.getIngredientForIngredientAmount(ingredients, item))
        .forEach(ingredient => {
          let nutrientPer100g = Nutrient.findNutrientByNumber(ingredient, nutrientId);
          if (nutrientPer100g)
          {
            if (nutrientPer100g.unit) {
              unit = nutrientPer100g.unit;
            } else {
              if (unit && unit !== ingredient.unit)
              {
                return "!";
              }
            }
          }
        });
    }
    return unit ? unit : '?';
  }

  static calculateTotalCalories(ingredients, ingredientAmounts) {
    if (!Nutrient.hasNonEmptyRecipe(ingredientAmounts))
    {
      return "-";
    }
    var total = 0.0;
    for (let item of ingredientAmounts) {
      var calories = Nutrient.findCaloriesInAmount(ingredients, item, 0.0);
      if (!isNaN(calories)) {
        total = total + calories;
      }
    }
    return total;
  }
}

export default Nutrient;