import React from 'react';

import LoginForm from "./LoginForm.js"
import ApiUserUtil from "./api/ApiUserUtil.js";

import "./App.css";

import { Alert, AlertTitle, Typography, Container, Button } from '@mui/material/';

class LoggedOutPage extends React.PureComponent {
  
  handleLogoutClick = () => {
    ApiUserUtil.requestLogOut({
      logoutSuccess: () => {
        console.log("Successfully Logged Out");
        window.open('/loggedOut');
      },
      logoutFailed: (message) => {
        console.log("Failed to logout. " + message);
      }
    });
  }

  render() {
    let contents;
    if (this.props.user)
    {
      contents = this.renderLogoutFailurePage();
    }
    else 
    {
      contents = this.renderLogoutSuccessPage();
    }
    return (
      <Container 
        maxWidth="lg"
        className="App"
      >
        {contents}
      </Container>
    );
  }

  renderLogoutSuccessPage() {
    return (
      <div>
        <Typography variant="h2" textAlign="center">
          You have been logged out.
        </Typography>
        <LoginForm />
      </div>
    );
  }

  renderLogoutFailurePage() {
    return (
      <Container 
        maxWidth="sm" >
        <Alert 
          variant="outlined"
          severity="error"
          >
          <AlertTitle>Error</AlertTitle>
          You do not seem to be logged out! Try again?
        </Alert>
        <Button 
          sx={{ m: 1, p: 2, pl: 15, pr: 15, }} 
          variant="contained" 
          onClick={this.handleLogoutClick}>
          Logout
        </Button>
      </Container>
    );
  }
}

export default LoggedOutPage;