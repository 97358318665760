import React from 'react';

import ApiUserUtil from "./api/ApiUserUtil.js";

import NutrientDataUtil from "./data/NutrientDataUtil.js";
import FoodDataUtil from "./data/FoodDataUtil.js";

import  { Alert, AlertTitle,  Button, Container, List, Checkbox, Divider,
          ListItem, ListItemText, ListItemButton, ListItemIcon
        } from '@mui/material/';


class NutrientPreferenceEditPage extends React.PureComponent 
{
  handleToggle = (nutrientId) => 
  {
    if (!this.props.user || !this.props.user.nutrients)
    {
      return;
    }
    let user = {...this.props.user};
    let nutrients = [...this.props.user.nutrients];

    console.log("Toggle: " + nutrientId + " - " + JSON.stringify(this.props.user.nutrients));

    const index = nutrients.indexOf(nutrientId);
    if (index > -1) {
      // Was enabled, toggle to disabled.
      nutrients.splice(index, 1); 
    } else {
      nutrients.push(nutrientId);
    }
    user.nutrients = nutrients;
    this.props.updateUser(user);
  }

  storeNutrientPreferences = () => {
    if (!this.props.user || !this.props.user.nutrients)
    {
      return;
    }
    ApiUserUtil.storeNutrientPreferences({
      nutrients: this.props.user.nutrients,
      success: () => {
        console.log("Successuflly stored Nutrient Preferences.");
      },
      failure: (message) => {
        console.log ("Failed to store the Nutrient Preferences. " + message);
      }
    });
  }

  render() {
    let userSelectedIds = [];
    let nutrientsCurrentlySelected = this.props.user && this.props.user.nutrients ?
      this.props.user.nutrients
          .map( nutrientId => {
            let nutrientName = NutrientDataUtil.getNutrientForId(nutrientId);
            if (nutrientName !== null)
            {
              userSelectedIds.push(nutrientId);
              return (
                <ListItem 
                  key={nutrientName}
                >
                  <ListItemButton role={undefined} onClick={() => this.handleToggle(nutrientId)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={true}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': nutrientName }}
                      />
                    </ListItemIcon>
                    <ListItemText id={nutrientName} primary={nutrientName} />
                  </ListItemButton>
                </ListItem>
              );
            }
            return null;
          })
      : "?";
    let totalFoodsCount = FoodDataUtil.getTotalFoodsCount();
    let allNutrients = NutrientDataUtil.allNutrientNames().map( nutrientName => {
      let nutrientId = NutrientDataUtil.getIdForNutrient(nutrientName);
      let count = NutrientDataUtil.getCountForNutrient(nutrientName);
      let percentage = ((count / totalFoodsCount) * 100).toFixed(0) + "%";
      return (
        <ListItem 
          key={nutrientName}
        >
          <ListItemButton role={undefined} onClick={() => this.handleToggle(nutrientId)} dense>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={userSelectedIds.includes(nutrientId)}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': nutrientName }}
              />
            </ListItemIcon>
            <ListItemText id={nutrientName} primary={nutrientName + " (" + percentage + ")"} />
          </ListItemButton>
        </ListItem>
      )
    });
    let storeButton = (
      <Button onClick={this.storeNutrientPreferences}>
        Store Nutrient Preferences.
      </Button>
    );
    return (
      <Container className="App" >
        {storeButton}
        <Divider />
        <List>
          {nutrientsCurrentlySelected}
        </List>
        <Divider />
        {storeButton}
        <Divider />
        <Alert severity="info"><AlertTitle>Note</AlertTitle>Many of these nutrients have very limited coverage in the ingredient dataset, the % is there to provide some guidance on what to expect.</Alert>
        <List>
          {allNutrients}
        </List>
        <Divider />
        {storeButton}
      </Container>
    );
  }
}

export default NutrientPreferenceEditPage;