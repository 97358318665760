import React from 'react';

import { TextField, InputAdornment } from '@mui/material/';

import useMediaQuery from '@mui/material/useMediaQuery';

export default function AmountTextField ({index, id, amount, unit, onAmountChanged}) 
{
  const isMobile = useMediaQuery('(max-width:1024px)');
  return (
    <TextField
        type="number"
        name="amount"
        label={isMobile ? "Amt" : "Amount"}
        size={isMobile ? "small": "normal"}
        defaultValue={amount || ""}
        error={id && (!amount || amount < 0) }
        sx={{ width: '7em'}}
        InputProps={{
          inputProps: { min: 0 }, 
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
        }}
        onBlur={(event) => onAmountChanged(index, parseFloat(event.target.value), unit)}
      />
  );
}
