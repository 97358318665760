import React from 'react';

import "./App.css";

import App from "./App.js";

import AccessDeniedPage from "./AccessDeniedPage.js";
import MealLogSection from "./food_input/MealLogSection.js";

import ApiUsdaUtil from "./api/ApiUsdaUtil.js";
import ApiFoodUtil from "./api/ApiFoodUtil.js";
import ApiUtil from "./api/ApiUtil.js";

import UrlUtil from './UrlUtil.js';

import NutritionTable from "./nutrition_table/NutritionTable.js"

import IngredientAmount from "./model/IngredientAmount.js"
import RecipeAmount from "./model/RecipeAmount.js";


import {Alert, AlertTitle, 
        Container, Grid,
        Typography,
        IconButton,
    } from '@mui/material/';

import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const MEALS = ['BREAKFAST', 'LUNCH', 'DINNER', 'SNACK'];
class FoodLogPage extends React.Component {
  constructor(props) {
    super(props);
    this.firstMountHasHappened = false;
    let date = this.getDateFromUrl();
    if (!date)
    {
      date = new Date();
    }
    console.log("Date: " + date);
    let dateText = date.toDateString();
    this.state = {
      date: date,
      mealToRecipeAmounts: {
        'BREAKFAST': Array(new RecipeAmount()),
        'LUNCH': Array(new RecipeAmount()),
        'DINNER': Array(new RecipeAmount()),
        'SNACK': Array(new RecipeAmount())
      },
      mealToIngredientAmounts: {
        'BREAKFAST': Array(new IngredientAmount()),
        'LUNCH': Array(new IngredientAmount()),
        'DINNER': Array(new IngredientAmount()),
        'SNACK': Array(new IngredientAmount())
      },
      mealToRecipeNames: {
        'BREAKFAST': dateText + " - Breakfast",
        'LUNCH': dateText + " - Lunch",
        'DINNER': dateText + " - Dinner",
        'SNACK': dateText + " - Snack",
      },
      titleToShowRows: {
        'BREAKFAST': false,
        'LUNCH': false,
        'DINNER': false,
        'SNACK': false,
      },
      ingredientsUsdaData: Array(0),
      error: false,
      errorMessage: null,
      successMessage: null,
    };
  }

  componentDidUpdate() {
    if (this.props.user && this.firstMountHasHappened === false) {
      this.getMealsInfo();
      this.firstMountHasHappened = true;
    }
  }

  getDateFromUrl() {
    let dateText = UrlUtil.getNamedUrlParam('date');
    return ApiUtil.parseDate(dateText);
  }
  
  getMealsInfo = () => {
    var self = this;
    console.log("Getting meals.");
    ApiFoodUtil.getMealsForADay({
      date: this.state.date, 
      username: this.props.user.name,
      success: (data) => {
        self.parseLoadedMealsAndSetState(data);
        console.log("Got Meal Data for: " + JSON.stringify(data.date));
      }, 
      failure: (message) => {
        console.log(message);
        self.setState({
          successMessage: null,
          errorMessage: "Failed to load meals: " + message,
        });
      }
    });
  }

  parseLoadedMealsAndSetState = (data) => {
    let mealToRecipeAmounts = {
      'BREAKFAST': [],
      'LUNCH': [],
      'DINNER': [],
      'SNACK': [],
    };
    // new RecipeAmount()
    let mealToIngredientAmounts = {
      'BREAKFAST': [],
      'LUNCH': [],
      'DINNER': [],
      'SNACK': [],
    };
    //new IngredientAmount()
    data['meals'].forEach(meal => {
      meal['recipe_amounts'].forEach(recipe => {
        let recipeIngredients = [];
        recipe['ingredient_amounts'].forEach(ra => recipeIngredients.push(
          new IngredientAmount(ra['name'], ra['fdc_id'], ra['amount'], ra['unit'])
        ));
        mealToRecipeAmounts[meal['meal_type']].push(
          new RecipeAmount(recipe['name'], recipe['recipe_id'], recipe['amount'], recipe['unit'], recipeIngredients)
        );
      });
      meal['ingredient_amounts'].forEach(ingredient => {
        mealToIngredientAmounts[meal['meal_type']].push(
            new IngredientAmount(ingredient['name'], ingredient['fdc_id'], ingredient['amount'], ingredient['unit'])
        );
      });
    });
    MEALS.forEach(mealName => {
      mealToRecipeAmounts[mealName].push(new RecipeAmount());
      mealToIngredientAmounts[mealName].push(new IngredientAmount());
    });
    this.setState({
      mealToRecipeAmounts: mealToRecipeAmounts,
      mealToIngredientAmounts: mealToIngredientAmounts,
      successMessage: null,
      errorMessage: null,
    });
    this.searchForIngredientsIfChanged(mealToRecipeAmounts, mealToIngredientAmounts);
  }

  setIntoArray(array, index, item) {
    var updatedArray = {...array};
    updatedArray[index] = item;

    return updatedArray
  }

  showHideNutritionRows = (mealName, showRows) => {
    mealName = mealName.toUpperCase();
    let updatedShowRows  = {...this.state.titleToShowRows};
    let show = showRows === null || showRows === undefined ? !this.state.titleToShowRows[mealName] : showRows;
    updatedShowRows[mealName] = show;
    console.log("ShowHideRows: " + mealName + " - " + showRows + " :Result: " + JSON.stringify(updatedShowRows));
    this.setState({
      titleToShowRows: updatedShowRows,
    });
  }

  recipeChanged = (index, changedRecipeAmounts, changedIngredientAmounts, changedRecipeName) => 
  {  
    let recipeDuplicates = RecipeAmount.getDuplicateIngredientAmounts(changedRecipeAmounts);
    let ingredientDuplicates = IngredientAmount.getDuplicateIngredientAmounts(changedIngredientAmounts);
    let duplicates = recipeDuplicates;
    ingredientDuplicates.forEach(d => duplicates.push(d));
    let error = duplicates.length > 0;
    let errorMessage = error ? "There are duplicated ingredients: " + JSON.stringify(duplicates, null, 2) : null;

    let updatedRecipeAmounts = this.setIntoArray(this.state.mealToRecipeAmounts, index, changedRecipeAmounts);
    let updatedIngredientAmounts = this.setIntoArray(this.state.mealToIngredientAmounts, index, changedIngredientAmounts);
    let updatedNames = this.setIntoArray(this.state.mealToRecipeNames, index, changedRecipeName);
    this.setState({
      mealToRecipeAmounts: updatedRecipeAmounts,
      mealToIngredientAmounts: updatedIngredientAmounts,
      mealToRecipeNames: updatedNames,
      error: error,
      errorMessage: errorMessage
    });
    this.searchForIngredientsIfChanged(updatedRecipeAmounts, updatedIngredientAmounts);
    this.saveFoodLog(updatedIngredientAmounts, updatedRecipeAmounts);
  }

  ingredientsDataCoversAllIngredientAmounts (mealToIngredientAmounts)
  {
    let ingredientIds = this.state.ingredientsUsdaData.map(fdcData => fdcData.fdc_id);

    for (let ingredientAmounts of Object.values(mealToIngredientAmounts)) {
      for (let item of ingredientAmounts) {
        const id = item.fdc_id
        if (id !== null && id !== undefined) {
          // need this ID.
          if (!ingredientIds.includes(id))
          {
            return false;
          }
        }
      }
    }
    return true;
  }

  searchForIngredientsIfChanged = (mealToRecipeAmounts, mealToIngredientAmounts) => {
    if (this.ingredientsDataCoversAllIngredientAmounts(mealToIngredientAmounts))
    {
      // console.log("Already have all required ingredients, skipping call.");
      return;
    }
    let fdc_ids = Array(0);
    for (let ingredinetAmounts of Object.values(mealToIngredientAmounts)) {
      for (let item of ingredinetAmounts) {
        const id = item['fdc_id'];
        if (id !== null) {
          fdc_ids.push(id);
        }
      }
    }
    for (let recipeAmounts of Object.values(mealToRecipeAmounts)) {
      
      recipeAmounts.forEach(ra => ra.getAllIngredientFdcIds().forEach(id => fdc_ids.push(id)))
    }

    let self = this;
    ApiUsdaUtil.searchForIngredients({
      fdc_ids: fdc_ids,
      nutrient_ids: self.props.user.nutrients,
      success: (responseData) => {
        let dataToSet = Array.isArray(responseData) ? responseData : Array(0);
        console.log("Found USDA data. ");
        self.setState({
          ingredientsUsdaData: dataToSet,
        });
      },
      failure: (message) => {
        console.log("Failed to get data from server. " + message);
        self.setState({
          errorMessage: "Problem querying the server. " + message,
        });
      }
    });
  }


  checkErrorsInAmountsList(type: String, errors: Array, amounts: Array, prototypeGenerator: Function, getDuplicateAmountsFunction: Function)
  {
    amounts.forEach( temp => {
      let item = prototypeGenerator();
      Object.assign(item, temp);
      if (item.hasId())
      {
        // If no fdc_id, it's an empty row, which is fine.
        if (!item.amount || !item.unit)
        {
          errors.push(type + " missing 'amount'(" + item.amount + ")(and/or 'unit'(" + item.unit + ")) - for (" + item.getId() + ")" + item.name);
        }
      }
    });
    for (let error of getDuplicateAmountsFunction(amounts))
    {
      errors.push("There are duplicated " + type + "s: " + error);
    }
  }

  saveFoodLog(mealToIngredientAmounts, mealToRecipeAmounts): Void {
    let errors = [];
    Object.values(mealToIngredientAmounts).forEach(ingredientAmounts => {
      this.checkErrorsInAmountsList(
        "Ingredient", errors, ingredientAmounts, () => new IngredientAmount(), IngredientAmount.getDuplicateIngredientAmounts
      );
    });
    Object.values(mealToRecipeAmounts).forEach(recipeAmounts => {
      this.checkErrorsInAmountsList(
        "Recipe", errors, recipeAmounts, () => new RecipeAmount(), RecipeAmount.getDuplicateIngredientAmounts
      );
    });

    if (errors.length === 0)
    {
      let self = this;
      ApiFoodUtil.storeMealsForADay({
        date: this.state.date,
        mealToIngredientAmounts: mealToIngredientAmounts,
        mealToRecipeAmounts: mealToRecipeAmounts,
        success: () => {
          console.log("Successfully Saved Food Log.");
          self.setState({
            successMessage: "Meals Logged.",
            errorMessage: null,
          });
          this.getMealsInfo();
        },
        failure: (message) => {
          console.log("Failure: " + message);
          self.setState({
            successMessage: null, 
            errorMessage: "Problem logging meals on the server. " + message,
          });
        },
      });
    }
    else{
      this.setState({
        errorMessage: JSON.stringify(errors, null, 2),
        successMessage: "",
      });
    }
  }

  getPreviousDay = () => {
    let date = new Date();
    date.setDate(this.state.date.getDate() - 1)
    return date;
  }
  getNextDay= () => {
    let date = new Date();
    date.setDate(this.state.date.getDate() + 1)
    return date;
  }

  render() {
    if (!this.props.user)
    {
      return <AccessDeniedPage user={this.props.user} updateUser={this.props.updateUser} redirectPath={App.FOOD_LOG_PATH}/>
    }

    var errorSection = this.state.errorMessage ? 
        (<Alert severity="error"><AlertTitle>Errors</AlertTitle><pre>{this.state.errorMessage}</pre></Alert>) 
        : "";
    var successSection = this.state.successMessage ? 
        (<Alert severity="success">{this.state.successMessage}</Alert>) 
        : "";


    let meals = [];
    MEALS.forEach(meal => {
        meals.push(<MealLogSection 
          key={meal}
          index={meal}
          mealName={meal}
          user={this.props.user}
          date={this.state.date}
          recipeAmounts={this.state.mealToRecipeAmounts[meal]}
          ingredientAmounts={this.state.mealToIngredientAmounts[meal]}
          recipeChanged={this.recipeChanged}
        />)
      });
    let saveButtonInGrid = "";  
    // saveButtonInGrid = (
    //   <Grid item xs={3}>
    //     <Button variant="contained"
    //       onClick={() => this.saveFoodLog()}
    //     >
    //       Log Foods
    //     </Button>
    //   </Grid>    
    // );
    
    return (
      <Container className="App">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center" 
        >
          <Grid item xs={1} sm={1} >
            <IconButton 
              aria-label="previous_day"
              href={App.FOOD_LOG_PATH + "?date=" + ApiUtil.formatDate(this.getPreviousDay())}
            > 
              <ArrowCircleLeftOutlinedIcon 
                sx={{ fontSize: 50 }} 
                />
            </IconButton>
          </Grid>
          <Grid item xs={8} sm={2} >
            <Typography variant="h4">{this.state.date.toLocaleDateString()}</Typography>
          </Grid>
          <Grid item xs={1} sm={1} >
            <IconButton 
              aria-label="next_day"
              href={App.FOOD_LOG_PATH + "?date=" + ApiUtil.formatDate(this.getNextDay())}
            >  
              <ArrowCircleRightOutlinedIcon
                sx={{ fontSize: 50 }} />
            </IconButton>
          </Grid>
        </Grid>
        {meals}
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={3}>
            {successSection}
          </Grid>      
          <Grid item xs={3}>
            {errorSection}
          </Grid>      
          {saveButtonInGrid}  
        </Grid>
        <Typography variant="h2">Nutrition</Typography>
        <NutritionTable 
          user={this.props.user}
          showSubTitles={false}
          showSubtotalsAtTop={true}
          ingredients={this.state.ingredientsUsdaData}
          titleToShowRows={this.state.titleToShowRows}
          titleToIngredientAmounts={this.state.mealToIngredientAmounts}
          titleToRecipeAmounts={this.state.mealToRecipeAmounts}
          showHideRowsFunction={this.showHideNutritionRows}
        />
      </Container>
    );
  }
}
  
export default FoodLogPage;