import React, { HTMLElement } from 'react';

import ApiUserUtil from "./api/ApiUserUtil.js"
import App from "./App.js"

import "./App.css";

import {Box,
        AppBar, Tooltip, Avatar,
        Menu, MenuItem,
        Toolbar, IconButton, Typography, Button
    } from '@mui/material/';

import MenuIcon from '@mui/icons-material/Menu';

class SiteHeader extends React.Component {
  /**
   * Props:
   * user: User 
   * updateUser: Function(User)
   */
  constructor(props) {
    super(props);

    this.state = {
        anchorElUser: null, 
    }
  }

  handleLogoutClick() {
    ApiUserUtil.requestLogOut({
      logoutSuccess: () => {
        console.log("Verified Logged Out");
        this.props.updateUser(null);
        window.open('/loggedOut',"_self");
      },
      logoutFailed: (error_message) => {
        console.log("Failed to logout. " + error_message);
      }
      });
  }

  setAnchorElUser (element) {
    this.setState({
        anchorElUser: element,
    });
  }

  handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setAnchorElUser(event.currentTarget);
  };
  handleCloseUserMenu = () => {
    this.setAnchorElUser(null);
  };


  stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  stringAvatar(name: string) {
    let parts = name.split(' ');
    let chars = "?"
    if (parts.length === 1)
    {
      chars = parts[0][0];
    } else {
      chars = parts[0][0] + parts[parts.length-1][0];
    }
    chars = chars.toLocaleUpperCase();
    return {
      sx: {
        bgcolor: this.stringToColor(name),
      },
      children: chars,
    };
  }

  loggedIn() {
    return this.props.user !== null;
  }

  renderLoginOrUserInfoSection() {
    if (!this.loggedIn())
    {
      return (<Button color="inherit" href="/login">Login</Button>);
    }
    else
    {
      return (
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title={this.props.user.name}>
            <IconButton onClick={this.handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar 
                {...this.stringAvatar(this.props.user.name)} 
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={this.state.anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(this.state.anchorElUser)}
            onClose={this.handleCloseUserMenu}
          >
            <MenuItem onClick={ () => {
              window.open('/my/recipes',"_self");
              this.handleCloseUserMenu();
            }}>
              <Typography textAlign="center">My Recipes</Typography>
            </MenuItem>  
            <MenuItem onClick={ () => {
              window.open('/my/account',"_self");
              this.handleCloseUserMenu();
            }}>
              <Typography textAlign="center">Account</Typography>
            </MenuItem>  
            <MenuItem 
              onClick={() => {
                this.handleLogoutClick();
                this.handleCloseUserMenu();
              }}
            >
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>  
          </Menu>
        </Box>
      );
    }
  }

  /* Props
   * * onLoginSuccess: function(String)
   */
  render() {
    let buttons = "";
    if (this.loggedIn())
    {
      buttons = (
        <div>
          <Button color="inherit" href={App.FOOD_LOG_PATH}>Log Food</Button>
          <Button color="inherit" href={App.CREATE_RECIPE_PATH}>Create Recipe</Button>
          <Button color="inherit" href={App.MY_RECIPES_PATH}>My Recipes</Button>
        </div>
      );
    } else
    {
      buttons = (<Button color="inherit" href={App.CREATE_RECIPE_PATH}>Create Recipe</Button>)
    }
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              href="/"
            >
              <MenuIcon />
            </IconButton>
            {buttons}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}/>
            {this.renderLoginOrUserInfoSection()}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}

export default SiteHeader;
