const DEV_CONFIG = 
{
  API_PATH: 'http://localhost:8000/api/v0',
};

const PROD_CONFIG = 
{
  API_PATH:'/api/v0',
};

const CONFIG = {
  // Add common config values here
  
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? PROD_CONFIG : DEV_CONFIG),
};

export default CONFIG;