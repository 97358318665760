import React from 'react';

import ApiUserUtil from "./api/ApiUserUtil.js";

import "./App.css";

import {Alert,
        Typography,
        Button, Link,
        TextField, InputAdornment, IconButton
    } from '@mui/material/';

import {Visibility, VisibilityOff} from '@mui/icons-material/';

const STATE_READY='READY';
const LOGIN_SUCCESS='SUCCESS';
const LOGIN_FAILED='FAILED';

class CreateAccountPage extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      email: null,
      showPassword: false,
      queryState: STATE_READY,
      errorMessage: null,
    }
  }

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  handleCreateAccountClick = () => {
    ApiUserUtil.handleCreateAccountClick({
      username: this.state.username,
      password: this.state.password,
      email: this.state.email,
      createSuccess: () => {
        console.log("Created Account.");
        this.setState({
          queryState: LOGIN_SUCCESS,
          errorMessage: null,
        });
        window.open("/login");
      },
      createFailed: (message) => {
        console.log("Failed to log in. " + message);
        this.setState({
          queryState: LOGIN_FAILED,
          errorMessage: "Account Creation Failed. " + message,
        });
      }
    });
  }

  /* Props
   * * onLoginSuccess: function(String)
   */
  render() {
    var errorSection = "";
    if (this.state.errorMessage) {
      errorSection = (<Alert severity="error">{this.state.errorMessage}</Alert>)
    }
    
    return (
      <div className="App">
        <Typography variant="h3">Create Account</Typography>
        <div>
          <TextField 
            id="username-field" 
            label="Username" 
            variant="outlined" 
            error={this.state.queryState === LOGIN_FAILED}
            onChange={(event) => {
              this.setState({
                username: event.target.value,
              });
            }}
          />
          <TextField 
            id="password-field" 
            type={this.state.showPassword ? "text" : "password"}
            label="Password" 
            variant="outlined" 
            error={this.state.queryState === LOGIN_FAILED}
            onChange={(event) => {
              this.setState({
                password: event.target.value,
              });
            }}
            InputProps={{
              endAdornment: 
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      var show = this.state.showPassword;
                      this.setState({
                        showPassword: !show,
                      });
                    }}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
            }}
          />
          <br/>
          <TextField 
            id="username-field" 
            label="Email" 
            variant="outlined" 
            onChange={(event) => {
              this.setState({
                email: event.target.value,
              });
            }}
          />
          
        </div>
        {errorSection}
        <div>
          <Button 
            sx={{ m: 1, p: 2, pl: 15, pr: 15, }} 
            variant="contained" 
            onClick={this.handleCreateAccountClick}>
            Create Account
          </Button>
        </div>
        Or: <Link href="/login">Login</Link>
      </div>
    );
  }
}

  
export default CreateAccountPage;