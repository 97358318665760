import React from 'react';

import CONFIG from "./config.js"
import ApiUserUtil from "./api/ApiUserUtil.js";

// import {useNavigate} from 'react-router-dom';

import "./App.css";
import $ from "jquery";

import {Container, Alert, AlertTitle,
        Button, TextField, InputAdornment, IconButton
    } from '@mui/material/';

import {Visibility, VisibilityOff} from '@mui/icons-material/';


const STATE_READY='READY';
const LOGIN_SUCCESS='SUCCESS';
const LOGIN_FAILED='FAILED';

function LoginButton(props) {
  // const navigate = useNavigate();

  const handleLoginClick = () => {
    const url = CONFIG.API_PATH + "/user/login?username=" + props.username + '&password=' + props.password;
    $.ajax({
        url: url,
        type: "GET",
        dataType:'json',
        xhrFields: {
          // Set cookies
          withCredentials: true
        },
        success(responseData, textStatus, jqXhr) {
          console.log("Logged in");
          props.loginSucceeded();
          let path = props.redirectPath ? props.redirectPath : '/';
          window.open(path, "_self")
          // navigate(path);
        },
        error(jqXhr, textStatus: String, errorThrown: String) {
          console.log("Failed to log in. " + textStatus);
          props.loginFailed()
        },
    })
  };

  return (
    <Button 
      sx={{ m: 1, p: 2, pl: 15, pr: 15, }} 
      variant="contained" 
      onClick={handleLoginClick}>
      Login
    </Button>
  );
}

class LoginForm extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      showPassword: false,
      queryState: STATE_READY,
    }
  }

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  /* Props
   * * onLoginSuccess: function(String)
   */
  render() {
    if (this.props.user)
    {
      return this.renderAlreadyLoggedIn();
    }
    else
    {
      return this.renderLoginForm();
    }
  }

  renderAlreadyLoggedIn() {
    return (
      <Container 
        maxWidth="lg"
        sx={{m: 1}} >
        <Alert severity="info">
          <AlertTitle>Logged in.</AlertTitle>
          You are already logged in.
        </Alert>
        <Button 
          sx={{ m: 1, p: 2, pl: 15, pr: 15, }} 
          variant="contained" 
          onClick={() => {
            ApiUserUtil.requestLogOut({
              logoutSuccess: () => {
                this.props.updateUser(null);
              },
              logoutFailed: () => {},
            });
          }}>
          Logout
        </Button>
      </Container>
    );
  }


  renderLoginForm() 
  {
    return (
      <Container 
        maxWidth="lg"
        sx={{m: 1}} >
        <div>
          <TextField 
            id="username-field" 
            label="Username" 
            variant="outlined" 
            error={this.state.queryState === LOGIN_FAILED}
            onChange={(event) => {
              this.setState({
                username: event.target.value,
              });
            }}
          />
          <TextField 
            id="password-field" 
            type={this.state.showPassword ? "text" : "password"}
            label="Password" 
            variant="outlined" 
            error={this.state.queryState === LOGIN_FAILED}
            onChange={(event) => {
              this.setState({
                password: event.target.value,
              });
            }}
            InputProps={{
              endAdornment: 
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      var show = this.state.showPassword;
                      this.setState({
                        showPassword: !show,
                      });
                    }}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
            }}
          />
          
        </div>
        <div>
          <LoginButton
            username={this.state.username}
            password={this.state.password}
            redirectPath={this.props.redirectPath}
            loginSucceeded={() => 
              this.setState({
                queryState: LOGIN_SUCCESS,
              })
            }
            loginFailed={() => 
              this.setState({
                queryState: LOGIN_FAILED,
              })
            }
          />
        </div>
      </Container>
    );
  }
}
  
export default LoginForm;