import foodToFdcId from "./foodToFdcId.json";

function reverseIdToFood() {
  let reversed = {}
  for (let key of Object.getOwnPropertyNames(foodToFdcId.foodToFdcId)) {
    reversed[foodToFdcId.foodToFdcId[key]] = key;
  }
  return reversed;
}
const ID_TO_FOOD = reverseIdToFood();

const FoodDataUtil = 
{
  getTotalFoodsCount: () => 
  {
    return Object.getOwnPropertyNames(foodToFdcId.foodToFdcId).length;
  },
}

export default FoodDataUtil;