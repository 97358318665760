import CONFIG from "./../config.js"
import ApiUtil from "./ApiUtil.js";

import $ from "jquery";

const ApiFoodUtil = {

  storeMealsForADay: ({
    date, // Date
    mealToIngredientAmounts,
    mealToRecipeAmounts,
    success, // Function
    failure, // Function
  }) => {
    const url = CONFIG.API_PATH + "/meal";
    let meals = {};
    Object.keys(mealToIngredientAmounts).forEach(meal => {
      meals[meal] = {
        meal_type: meal, 
        ingredient_amounts: mealToIngredientAmounts[meal],
      }
    });
    Object.keys(mealToRecipeAmounts).forEach(meal => {
      if (!(meal in meals))
      {
        meals[meal] = {meal_type: meal} 
      }
      meals[meal].recipe_amounts = mealToRecipeAmounts[meal];
    });


    let data = {
      date: date.toISOString().split('T')[0], 
      meals: Object.values(meals),
    };

    console.log("Posting meals: " + data.date + " : " + JSON.stringify(data.meals.map(m => m.meal_type)));

    ApiUtil.ajaxPostSetup();
    $.post({
      url: url, 
      data: data, 
      dataType: 'json'})
      .done((responseData, textStatus, jqXhr)=>  {
          success();
      })
      .fail((jqXhr, textStatus: String, errorThrown: String) => {
          failure("Failed to save the recipe. " + jqXhr.responseText);
    });
  },

  getMealsForADay: ({
    date, // Date
    username,
    success, // Function
    failure, // Function
  }) => {
    const url = CONFIG.API_PATH + "/meal/" + username + "/" + ApiUtil.formatDate(date);

    console.log("Requesting meals: " + url);
    $.ajax({
      url: url,
      type: "GET",
      dataType:'json',
      xhrFields: {
        // Set cookies
        withCredentials: true
      },
      success(responseData, textStatus, jqXhr) {
        success(responseData);
      },
      error(jqXhr, textStatus: String, errorThrown: String) {
        failure("Failed to get meals. " + jqXhr.responseText);
      },
    });
  }
}
  

export default ApiFoodUtil;
