import React from 'react';

import "./../App.css";

import RecipeEditor from "./RecipeEditor.js";

import StringUtil from "./../utils/StringUtil.js";

import { Container, Typography } from '@mui/material/';


class FoodLogPage extends React.PureComponent {
  /**
   * Properties:
   *  * index
   *  * user
   *  * date
   *  * mealName
   *  * recipeAmounts
   *  * ingredientAmounts
   *  * recipeChanged (index, recipeAmounts, ingredientAmounts, recipeName)
   */


  render() {
    return (
      <Container className="App">
        <Typography variant="h4">{StringUtil.capitalizeFirstLetter(this.props.mealName)}</Typography>
        <RecipeEditor 
          user={this.props.user}
          recipeAmounts={this.props.recipeAmounts}
          ingredientAmounts={this.props.ingredientAmounts}
          recipeName={this.props.date.toDateString() + " - " + this.props.mealName}
          recipeChanged={(recipeAmounts, ingredientAmounts, recipeName) => this.props.recipeChanged(this.props.index, recipeAmounts, ingredientAmounts, recipeName)}
          allowRecipeAmounts={true}
          showSaveAsRecipe={true}
        />
      </Container>
    );
  }
}
  
export default FoodLogPage;